import React from 'react'


function ImageContainer(props) {

    return (
        <div className = 'imageContainer' >
        <img style = {{maxHeight : '100%', maxWidth : '100%'}} src = {props.image}/>
        </div>
    )
}

export default ImageContainer;