import React from 'react'
import MoodSelectOptions from "./MoodSelectOptions";
import {useSelector} from "react-redux";
import {Col, Container, Jumbotron, Row} from "react-bootstrap";
import TextComponent from "../common_components/TextComponent";
import ButtonPanel from "../common_components/ButtonPanel";
import {sendProfile} from "./server_connection/server_requests";

function MoodSelect(props) {
     const substep = props.substep;
     const id = useSelector(store => store.refID);
     const gender = useSelector(state => state.welcome_data.gender);
     const age = useSelector(state => state.welcome_data.age);
     const mood = useSelector(state => state.mood);
     const key = useSelector(state => state.key);
     if(substep !== 'mood_select') {
          return null;
     }

     const isInvalid = () => {
          return substep === 'mood_select' && mood === '';
     };

     return (
         <Jumbotron>
              <Container fluid>
                   <Row >
                        <Col xs = {12} xl={6}>
                             <TextComponent hideText = {false} substep={substep}/>
                        </Col>
                        <Col xs={12} xl={6}>
                             <MoodSelectOptions mood = {mood} gender = {gender}/>
                        </Col>
                   </Row>
                   <ButtonPanel
                       next = {() => {sendProfile(id, age, gender, mood, key); props.next()}}
                       prev = {props.prev}
                       isInvalid = {() => isInvalid()}
                       substep = {props.substep}/>
              </Container>
         </Jumbotron>
     )
}

export default MoodSelect;