const questions = [
    {
        id : '1',
        text : 'Ervaar je geluidsoverlast in het dagelijks leven?',
        type : 'options',
        multiple : false,
        conditional : false,
        options : [{id : 1, text :'Ja'}, {id : 2, text :'Nee'}]
    },
    {
        id : '2',
        text : 'Hoeveel last heb je van geluidsoverlast?',
        type : 'slider',
        conditional : false,
        options : ['Amper', 'Heel erg']
    },
    {
        id : '3',
        text : 'Als je de geluidsbron niet hoort, beïnvloedt het je dan nog steeds?',
        type : 'slider',
        conditional : false,
        options : ['Amper', 'Heel erg']

    },
    {
        id : '3_1',
        text : 'Kun je beschrijven hoe de bron invloed heeft als je hem niet hoort?',
        type : 'textbox',
        conditional : true,
        dependency : '3',
        displayOn : ["1", "2", "3", "4", "5", "6"],
        options : []
    },
    {
        id : '4',
        text : 'Wanneer ervaar je meestal geluidsoverlast? Hier zijn meerdere keuzes mogelijk.',
        type : 'options',
        conditional : false,
        multiple : true,
        options : [{ id : 1, text :'In de middag'},
            {id : 2, text : 'In de nacht'},
            {id : 3, text :'Vroeg in de ochtend'},
            {id : 4, text : 'Bij het in slaap vallen'}]
    },
    {
        id : '5',
        text : 'Waar ervaar je meestal geluidsoverlast?',
        type : 'options',
        conditional : false,
        multiple : false,
        options : [{id : 1, text :'Buiten'}, {id : 2, text : 'Binnen'}, {id : 3, text :'Zowel buiten als binnen'}]
    },
    {
        id : '5_1',
        text : 'Kun je beoordelen hoe erg je buiten overlast van de bron ervaart?',
        type : 'slider',
        conditional : true,
        options : ['Amper', 'Heel erg'],
        dependency : '5',
        displayOn : [1, 3]
    },
    {
        id : '5_2_1',
        text : 'Kun je beoordelen hoe erg je binnen overlast van de bron ervaart?',
        type : 'slider',
        conditional : true,
        options : ['Amper', 'Heel erg'],
        dependency : '5',
        displayOn : [2, 3]
    },
    {
        id : '5_2_2',
        text : 'Waar ervaar je normaal gesproken geluid binnenshuis?',
        type : 'options',
        conditional : true,
        multiple : true,
        options : [{id : 1, text : 'Slaapkamer'},
            {id : 2, text :'Huiskamer'},
            {id : 3, text : 'Keuken'},
            {id : 4, text :'Zolder'},
            {id : 5, text :'Badkamer/ toilet'}],
        dependency : '5',
        displayOn : [2, 3]
    },
    {
        id : '5_2_3',
        text : 'Als je de ramen van de kamer sluit, in hoeverre reduceert het de hinderlijkheid van de bron?',
        type : 'slider',
        conditional : true,
        options : ['Amper', 'Heel veel'],
        dependency : '5',
        displayOn : [2, 3]
    },
    {
        id : '6',
        text : 'Vergeet je de hinderlijke bron op het moment dat het geluid stopt, of blijft het je beïnvloeden?',
        type : 'slider',
        conditional : false,
        options : ['Ik vergeet het direct', 'De hinder blijft gelijk']
    },
    {
        id : '7',
        text : 'Als de hinderlijke bron hoorbaar is is, kun jij je dan nog op verschillende taken concentreren?',
        type : 'slider',
        conditional : false,
        options : ['Amper, ik raak steeds afgeleid', 'Ik kan me prima concentreren']
    },
    {
        id : '8',
        text : 'Als je vanuit huis werkt, in hoeverre heeft de geluidsbron invloed op jouw productiviteit?',
        type : 'slider',
        conditional : false,
        options : ['Amper ', 'Veel, ik ben dan niet erg productief'],
        neutral : 'Ik werk niet vanuit huis'
    },
    {
        id : '9',
        text : 'Merk je een toename in alertheid door het effect van de hinderlijke geluidsbron?',
        type : 'slider',
        conditional : false,
        options : ['Nee', 'Zeer sterk']
    },
    {
        id : '10',
        text : 'Ervaar je dat je de geluidsbron beter kunt detecteren dan anderen? Bijvoorbeeld dat je de bron veel eerder hoort dan je gasten.',
        type : 'slider',
        conditional : false,
        options : ['Niet echt', 'Ja, ik ben beter in het detecteren']
    },
    {
        id : '11',
        text : 'Ervaar je over het algemeen meer negatieve emoties als woede, angst, verdriet of walging ten gevolge van de bron?',
        type : 'slider',
        conditional : false,
        options : ['Helemaal niet', 'Ja, heel erg']
    },

];

export default questions;