const fields = [
    {
        id : 'fullname',
        text : 'Voornaam & achternaam',
        type : 'Text',
        limit : 200
    },
    {
        id : 'email',
        text : 'E-mailadres',
        type : 'Text',
        limit : 200
    },
    {
        id : 'phone',
        text : 'Telefoonnummer',
        type : 'Number',
        limit : 15
    },
    {
        id : 4,
        text : 'Ik wil niet deelnemen aan het volgende onderzoek',
        type : 'Radio'
    }
    ];

export default fields;