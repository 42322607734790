const fields = [
    {
        id : 'postcode',
        text : '* De vier nummers van je postcode',
        type : 'Number',
        limit : 4
    },
    {
        id : 'edu_background',
        text : '* Opleidingsachtergrond',
        type : 'options',
        options : [{id : 1, text : 'Middelbare school'},
            {id : 2, text : 'MBO'},
            {id : 3, text :'HBO'},
            {id : 4, text :'WO'},
            {id : 5, text :'Vertel ik liever niet'}]
    },
    {
        id : 'income',
        text : '* Jaarlijks inkomen',
        type : 'options',
        options : [{id : 1, text :'Minder dan \u20ac25.000'},
            {id : 2, text : '\u20ac25.000 - \u20ac40.000'},
            {id : 3, text :'\u20ac40.000 - \u20ac60.000'},
            {id : 4, text :'\u20ac60.000 - \u20ac80.000'},
            {id : 5, text : '\u20ac80.000 - \u20ac100.000'},
            {id : 6, text :'\u20ac100.000 - \u20ac150.000'},
            {id : 7, text :'\u20ac150.000 - \u20ac200.000'},
            {id : 8, text :'Meer dan \u20ac200.000'},
            {id : 9, text :'Vertel ik liever niet'}]
    },

    {
        id : 'ethnicity',
        text : '* Ethniciteit',
        type : 'options',
        options : [{id : 1, text :'Nederlander'},
            {id : 2, text :'Europeaans'},
            {id : 3, text :'Aziatisch '},
            {id : 4, text :'Afrikaans'},
            {id : 5, text :'Noord Amerikaans'},
            {id : 6, text :'Zuid Amerikaans'},
            {id : 7, text :'Oceanisch'}]
    },
    {
        id : 'relationship',
        text : '* Relatie status',
        type : 'options',
        options : [{id : 1, text :'Getrouwd'},
            {id : 2, text :'Single of samenwonend'},
            {id : 3, text :'Anders'},
            {id : 4, text :'Vertel ik liever niet'}]
    },
    {
        id : 'house_time',
        text : '* Hoe lang leef je in je huidige woning',
        type : 'options',
        options : [{id : 1, text : 'Minder dan 1 jaar'},
            {id :2, text : '1 - 3 jaar'},
            {id : 3, text : '3 - 5 jaar'},
            {id : 4, text : '5 - 10 jaar'},
            {id : 5, text : '10 - 20 jaar'},
            {id : 6, text : 'Meer dan 20 jaar'}]
    }
];
export default fields;