import axios from "axios";

export const pushHum = (ID, volume) => {
    axios.post('dummy link hum',
        {ref_id : ID, volume: parseInt(volume)})
        .then(res => {
            console.log(res);
        })
};

export const pushSelectedSounds = (ID, sounds, key, soundscapeID) => {
    axios.post(window.host + 'soundscape-reaction/',
        {soundscape_id : soundscapeID, answer: processSounds(sounds)},
            {headers :  {Authorization : key}})
        .then(res => {
            console.log(res);
        }).catch(err => console.log('Error sending reaction'))
};

export const processSounds = (sounds) => {
    return Object.keys(sounds).map(x=>(
        {
            sound_name : x,
            volume : sounds[x]
        }
    ))
};