export const selectedSounds = (state = {initialSounds: {}, indoorSounds: {}}, action) => {
    const init = {};
    switch (action.type) {
        case 'ADDSOUND':
            return {
                ...state,
                [action.payload.soundType]: {
                    ...state[action.payload.soundType],
                    [action.payload.sound]: 20
                }
            };

        case 'REMOVESOUND'    :
            delete state[action.payload.soundType][action.payload.sound];
            return state;



        case 'CHANGEVOLUME' :
            return {
                ...state,
                [action.payload.soundType]: {
                    ...state[action.payload.soundType],
                    [action.payload.sound]: action.payload.volume
                }
                // [action.payload.soundType] : Object.keys(state[action.payload.soundType]).map(x=>
                //     (x === action.payload.sound) ? {[x] : action.payload.volume} : {[x] : state[action.payload.soundType][x]})

            };

        default :
            return state
    }
};

export const hum = (state = 50, action) => {
    switch (action.type) {
        case 'ADDHUMVOLUME' :return parseInt(action.payload);
        default : return state;
    }
};

