import React, {useEffect, useState} from 'react'
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import {Container} from "react-bootstrap";
import {useDispatch, useSelector} from "react-redux";
import {activate} from "./redux/actions";

function Break() {
    const invalid = useSelector(store => store.breakInvalid);
    const [percentage, setPercentage] = useState(invalid ? 0 : 100);
    const dispatch = useDispatch();

    const time = 300;

    useEffect(() => {
        const timeout = setTimeout(() => {
            if(percentage <100 && invalid === true) {
            setPercentage(percentage + 1)}
        }, time);

        if(percentage >= 100) {
            dispatch(activate())
        }

        return () => {
            clearTimeout(timeout);
        };
    },[percentage]);

    return (
        <Container className = "mx-lg-5 my-lg-3">
            <CircularProgressbar
                className="mx-5"
                value={percentage}
                text={invalid ? "Laden..." : "100%"}
                strokeWidth={20}
                styles={{
                    // Customize the root svg element
                    root: {
                        width : '60%',
                        height : '80%',
                        filter: 'drop-shadow(10px 10px 20px lightgray)'
                    },
                    // Customize the path, i.e. the "completed progress"
                    path: {
                        // Path color
                        stroke: `rgb(5, 88, 252)`,

                    },
                    // Customize the circle behind the path, i.e. the "total progress"
                    trail: {
                        // Trail color
                        stroke: '#EBEEF1',
                        //strokeWidth : 0
                    },
                    // Customize the text
                    text: {
                        // Text color
                        fill: '#393E46',
                        fontWeight : 'bold',
                        // Text size
                        fontSize: '0.7vmin',
                    },
                    // Customize background - only used when the `background` prop is true
                    background: {
                        fill: '#3e98c7',
                    },
                }}/>
        </Container>
    )
}

export default Break;