import React, {useEffect, useState} from 'react';
import WelcomeManager from './welcome_step/WelcomeManager'
import Footer from './Footer'
import SoundscapeManager from "./soundscape_step/SoundscapeManager";
import QuestionManager from "./mood_step/QuestionManager";
import {useSelector, useDispatch} from "react-redux";
import {nextStep, nextSubStep, prevStep, prevSubStep, setSoundscapeID} from "../redux_files/actions";
import UserResponseManager from "./user_response_step/UserResponseManager";
import step_data from './data_constant/step_data'
import InformationManager from "./information_step/InformationManager";
import Howler from './Howler'
import Login from "./login_step/Login";
import axios from "axios";
import ReactHowler from "react-howler";

function ComponentManager() {
    const step = useSelector(state => state.step);
    const substep = useSelector(state => state.substep);
    const dispatch = useDispatch();
    const [count, setCount] = useState(0);
    const refID = useSelector(store => store.refID);
    const key = useSelector(store => store.key);
    const soundscapeID = useSelector(store => store.soundscapeID);

    const current_substeps = step_data[step].substeps;

    const prev =() => {
        setCount(count -1);
        if(substep === current_substeps[0]) {
            dispatch(prevSubStep(step_data[step-1].substeps.slice(-1)[0]));
            dispatch(prevStep());
        } else {
            dispatch(prevSubStep(current_substeps[current_substeps.findIndex(x => x===substep) -1]));
        }
    };

    useEffect(() => {
        if(step === 3 || step === 5 || step === 6) {
            const type = step === 3? 1 : step === 5 ? 2 : 3;
            axios.post(window.host + 'soundscape/',
                {soundscape_id: 0, ref_id: refID, type: type},
                {headers: {Authorization: key}})
                .then(res => dispatch(setSoundscapeID(step, res.data.soundscape_id)))
                .catch(err => console.log("error getting id"))
        }
    }, [step]);

    useEffect(() => {
        window.onbeforeunload = function () {
            if(substep !== 'end' && substep !== 'login') {
                return ('You might lose all your changes')
            }
        }
    }, [substep]);

    const next = () => {
        setCount(count + 1);
        if(substep === current_substeps[current_substeps.length -1] ) {
            dispatch(nextSubStep(step_data[step+1].substeps[0]));
            dispatch(nextStep());
        } else {
            dispatch(nextSubStep(current_substeps[current_substeps.findIndex(x=> x===substep) +1]))
        }
    };

    const scrollToTop = () => {
        window.scrollTo({
            top: 0
        });
    };

    return (
        <div>
            <Login step = {step} next = {next}/>
            <WelcomeManager scroll = {scrollToTop} prev = {prev} next = {next} step = {step} substep = {substep}/>
            <QuestionManager prev = {prev} next = {next} step = {step} substep = {substep}/>
            <SoundscapeManager prev = {prev} next = {next} step = {step} substep = {substep}/>
            <UserResponseManager prev = {prev} next = {next} step = {step} substep = {substep}/>
            <InformationManager prev = {prev} next = {next} step = {step} substep = {substep}/>

            <Footer percentage = {substep === 'end' ? 100 : (count) * 100/34}/>
            {step === 4 || step === 5 || step === 6 ?
                <Howler step = {step} substep = {substep} soundscapeID = {soundscapeID[step === 4 ? 3 : step]}/>
                :null}
        </div>
    )
}

export default ComponentManager;

