import React from 'react';
import {Container, Row} from "react-bootstrap";
import Question from '../data_constant/textpanel.json'


function TextComponent(props) {
    const data = Question.map((element) => element[props.substep]);
    let lines = data[0].text.split(';');
    return (
        <Container fluid>
            <Row className = {"mb-4 mr-4"} style = {{lineHeight : '0.95'}}>
                <p className = "header pr-5">{data[0].header}</p>
            </Row>

            {lines.map((line) => <Row className = {"my-3 mr-lg-4"} style = {{lineHeight : '1.4'}}>
                <p className = {props.hideText === true ? "d-none d-md-block pr-lg-3" : "pr-lg-3"}>{line}</p>
            </Row>)}
        </Container>
    )
}

export default TextComponent;