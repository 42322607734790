import React from 'react';
import Icon from "./Icon";
import {Container, Row, Col} from 'react-bootstrap';
import outdoor_noise_assets from '../data_constant/outdoor_noise_icon';
import indoor_noise_assets from '../data_constant/other_noise_icon'
import assets from '../data_constant/noise_icon'

function SoundSelectOptions(props) {
    if (props.substep !== 'outdoor_sound_select' &&
        props.substep !== 'indoor_sound_select') {
        return null;
    }

    const scrollableStyle = {
        overflowX: 'hidden',
        overflowY : 'auto' ,
        width : "100%",
        height : "65vh"
    };

    const keys = props.substep === 'outdoor_sound_select' ?
        outdoor_noise_assets: indoor_noise_assets;

    const getIcon=(data)=> {
        return (
            <Col key = {data.name}>
                <Icon imageLink = {data.image}
                      name = {data.name}
                      audio = {data.audio15}
                      selected = {props.selected}
                      step = {props.step}
                      setData = {props.setData}
                      substep = {props.substep}/>
                <p style={textStyle}> {data.name} </p>
            </Col>
        )
    };

    const textStyle = {
        textAlign: 'center',
        fontSize : '13px',
        fontColor: '#393E46',
        fontWeight : 'thin',
        margin : '7px'
    };

    return (
        <Container className = "containerStyle scrollable">
            <Row lg={4} md={4} sm={2} xs={2} >
                {keys.map((key) => getIcon(assets[`${key}`]))}
            </Row>
        </Container>
    );
}

export default SoundSelectOptions;