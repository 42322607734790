export const email = (state = '', action) => {
    if (action.type === 'SETEMAIL') {
        return action.payload;
    } else {
        return state
    }
};

export const password = (state = '', action) => {
    if(action.type === 'SETPASSWORD') {
        return action.payload;
    } else {
        return state
    }
};

export const key = (state = '', action) => {
    if (action.type === 'SETKEY') {
        return action.payload;
    } else {
        return state;
    }
};
