import React from 'react'
import {Col, Container, Jumbotron, Row} from "react-bootstrap";
import sensitivity_ques from "../data_constant/sensitivity_questions";
import opinions_ques from '../data_constant/opinion_ques'
import hopes_ques from '../data_constant/hope_ques'
import info_ques from '../data_constant/info_questions'
import ButtonPanel from "./ButtonPanel";
import {useDispatch, useSelector} from "react-redux";
import OptionRow from './OptionRow'
import {pushTableAnswer} from "./server_connection/server_requests";
import {setSensitivityQuestion} from "../welcome_step/redux/actions";

function TableQuestion(props) {
    const count = parseInt(useSelector(store => store.table_question)[props.substep]);
    const id = useSelector(store => store.refID);
    const answers = useSelector(store => store.sensitivity)[props.substep];
    const options = ['geheel oneens', 'oneens', 'enigszins oneens', 'enigszins eens', 'eens', 'geheel eens'];
    const key = useSelector(store => store.key);

    const dispatch = useDispatch();

    if (props.substep !== 'sound_sensitivity' && props.substep !=='opinions' && props.substep !=='hopes' && props.substep!=='questions') {
        return null;
    }

    const ques = props.substep === 'sound_sensitivity' ?
        sensitivity_ques :
        props.substep === 'opinions' ?
            opinions_ques : props.substep === 'hopes' ?
            hopes_ques : info_ques;

    /**
     * When user presses next, if there are any more questions left, display question.
     * Else go to mood select
     */
    const nextCheck = () => {
        if (count < ques.length - 4) {
            dispatch(setSensitivityQuestion(count + 4, props.substep));
        } else {
            pushTableAnswer(id, answers, props.substep, key);
            props.next();
        }
    };

    /**
     * When user presses prev, if there are questions left, go to questions.
     * Else go to welcome data step
     */
    const prevCheck = () => {
        if (count === 0) {
            props.prev()
        } else {
            dispatch(setSensitivityQuestion(count-4, props.substep));
        }
    };

    /**
     * If for the current count, no answer is filled out, return true
     * Else return false
     */
    const isInvalid = () => {
        for(let i = count; i < count + 4 && i < ques.length; i++) {
            if(answers[i+1] == undefined) {
                return true;
            }
        }
        return false;
    };


    return (
        <Container fluid className = "py-5">
            <Row >
                <Col md={5}/>
                <Col md={7} className = "d-none d-md-block">
                    <Row className = "mb-2">
                        {options.map(x=>
                            <Col>
                                <Row>
                                    <p style = {{fontSize : '1rem', paddingRight : '25%', paddingLeft: '25%', textAlign : 'center' }} className="mx-auto">
                                        {x}
                                    </p>
                                </Row>
                            </Col>
                        )}
                    </Row>
                </Col>
            </Row>

            {ques.slice(count, count + 4).map(x=>
                <OptionRow id = {x.id} text = {x.text} options = {options} substep = {props.substep}/>
            )}

            <Jumbotron className = "never" >
                <ButtonPanel next = {() => nextCheck()} prev = {() => prevCheck()} isInvalid = {() => isInvalid()}/>
            </Jumbotron>
        </Container>

    );
}

export default TableQuestion;