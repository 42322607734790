/**
 * Words for verbal test (step 4)
 * @type {string[][]}
 */
const verbal = [

    ['Stil', 'Luid', 1], ['Ontspannen', 'Snel', 2], ['Scherp', 'Niet scherp', 3], ['Helder', 'Onduidelijk', 4],
    ['Eenvoudig', 'Ingewikkeld', 5], ['Binnen', 'Buiten', 6], ['Ruim', 'Omhullend', 7], ['Dichtbij', 'Ver', 8],
    ['Harmonisch', 'Dissonant', 9], ['Dynamisch', 'Eentonig', 10], ['Natuurlijk', 'Kunstmatig', 11], ['Ritmisch', 'Onregelmatig', 12],
    ['Kalmerend', 'Stress verhogend', 13], ['Een gevoel van thuishoren', 'Een gevoel van vervreemding', 14], ['Rustig', 'Veeleisend', 15], ['Bekend', 'Uniek', 16],
    ['Gezond', 'Ongezond', 17], ['Verfrissend', 'Afleidend', 18], ['Veilig', 'Bedreigend', 19], ['Opbeurend', 'Depressief', 20],
    ['Zinvol', 'Zinloos', 21], ['Heeft de voorkeur', 'Niet de voorkeur', 22], ['Saai', 'Interessant', 23], ['Levenloos', 'Levendig', 24],
    ['Comfortabel', 'Ongemakkelijk', 25] , [], [], []
];

export default verbal;