/**
 * Questions for sound sensitivity test
 */
const ques = [
    {
        id : '1',
        text : "Ik zou het niet erg vinden om in een lawaaierige omgeving te wonen als het appartement voldoende leuk is."
    },
    {
        id : '2',
        text : "Ik ben mij meer bewust van geluid dan vroeger."
    },
    {
        id : '3',
        text : "Niemand zou het erg moeten vinden als iemand zijn stereo-installatie af en toe op maximaal/hoog volume aanzet."
    },
    {
        id : '4',
        text : "In de bioscoop stoor ik mij aan gefluister en krakende snoeppapiertjes."
    },
    {
        id : '5',
        text : "Ik word gemakkelijk gewekt door geluid."
    },
    {
        id : '6',
        text : "Als het luidruchtig is waar ik werk, probeer ik de deur of het raam te sluiten, of ergens anders heen te gaan."
    },
    {
        id : '7',
        text : 'Het irriteert mij als mijn buren luidruchtig zijn.'
    },
    {
        id : '8',
        text : 'Ik kan makkelijk wennen aan de meeste geluiden.'
    },
    {
        id : '9',
        text : 'Ik zou het erg vinden als een appartement dat ik wil huren zich tegenover een brandweerkazerne bevindt.'
    },
    {
        id : '10',
        text : 'Soms werken geluiden mij op mijn zenuwen en irriteren ze mij.'
    },
    {
        id : '11',
        text : 'Zelfs muziek die ik normaal gesproken leuk vind, stoort mij als ik me probeer te concentreren.'
    },
    {
        id : '12',
        text : 'Ik stoor mij niet aan de dagelijkse geluiden van mijn buren (zoals voetstappen, stromend water, een deur, enz.).'
    },
    {
        id : '13',
        text : 'Als ik alleen wil zijn, stoort het mij om geluiden van buiten te horen.'
    },
    {
        id : '14',
        text : 'Ik kan mij goed concentreren, wat er ook om mij heen gebeurt.'
    },
    {
        id : '15',
        text : 'In een bibliotheek stoor ik mij niet aan mensen die rustig een gesprek voeren.'
    },
    {
        id : '16',
        text : 'Er zijn vaak momenten dat ik volledige stilte zoek.'
    },
    {
        id : '17',
        text : 'Motorfietsen zouden betere uitlaatdempers moeten hebben.'
    },
    {
        id : '18',
        text : 'Op een lawaaierige plek kan ik mij moeilijk ontspannen.'
    },
    {
        id : '19',
        text : 'Ik word boos op mensen die lawaai maken waardoor ik niet in slaap val of mijn werk niet gedaan krijg.'
    },
    {
        id : '20',
        text : 'Ik zou het niet erg vinden om in een appartement met dunne muren te wonen.'
    },
    {
        id : '21',
        text : 'Ik word gemakkelijk gewekt door lawaai.'
    }
];

export default ques;