import React from 'react'
import {Container, Row, Col, Image, Jumbotron} from 'react-bootstrap'
import WelcomeImage from './resource/img/welcome_screen_image.png'
import TextComponent from "../common_components/TextComponent";

/**
 * First screen seen by the user
 * Contains information about the survey and start button
 */
function WelcomeScreen(props) {

    /**
     * Do not render if substep is not equal to 'welcome_screen'
     */
    if (props.substep !== 'welcome_screen') {
        return null;
    }

    /**
     * Renders welcome text and start survey button
     */
    return (
        <Jumbotron>
            <Container fluid>
                <Row xs={1} md={1} lg={1} xl={2}>
                    <Col>
                        <Image src= {WelcomeImage} fluid rounded/>
                    </Col>
                    <Col className= "p-3">
                        <Row className = "mx-lg-5">
                            <TextComponent hideText = {false} substep = {props.substep}/>
                        </Row>
                        <Row className = "mx-lg-5">
                            <button id='start' onClick={() =>{props.scroll(); props.next()}} className="mt-4 mx-md-0 mx-sm-auto mx-xs-auto">Onderzoek starten</button>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </Jumbotron>
    )
}

export default WelcomeScreen;