import React from 'react'
import SoundSelectOptions from "./SoundSelectOptions";
import {useSelector, useDispatch} from "react-redux";
import {Col, Container, Jumbotron, Row} from "react-bootstrap";
import TextComponent from "../common_components/TextComponent";
import Volume from "./Volume";
import ButtonPanel from "../common_components/ButtonPanel";
import Hum from "./Hum"
import {pushSelectedSounds} from "./server_connection/server_request";
import {pushNoiseAnswer} from "../mood_step/server_connection/server_request";
import {setLoading} from "../../redux_files/actions";

/**
 * Used to manage soundscape steps
 * Step 3 : Choosing initial sounds
 * Step 6 : Picking extra sounds for a nicer soundscape
 */
function SoundscapeManager(props) {
    const dispatch = useDispatch();
    const step = props.step;
    let selectedSounds = useSelector(store => store.selectedSounds);
    const substep = props.substep;
    const hum = useSelector(store => store.hum);
    const id = useSelector(store => store.refID);
    const selectedBasicNoise = useSelector(store => store.noiseQues);
    const key = useSelector(store => store.key);
    const soundscapeID = useSelector(store => store.soundscapeID);

    const selected = step === 3 && (substep === 'outdoor_sound_select' || substep === 'outdoor_volume')?
        selectedSounds.initialSounds : {...selectedSounds.indoorSounds, ...selectedSounds.initialSounds};

    if(step!==3) {
        return null;
    }

    /**
     * If the substep involves selecting initial sounds and no sounds have been picked, return true
     * Else return false
     */
    const isInvalid = () => {
        if(substep === 'outdoor_sound_select') {
            return Object.keys(selected).length === 0;
        }
        else return false;
    };

    const pushData = () => {

        if(substep === 'hum') {
            //pushHum(id, hum);
            pushNoiseAnswer(id, selectedBasicNoise, key, hum)
        } else if(substep === 'indoor_volume') {
            pushSelectedSounds(id, selected, key, soundscapeID[props.step]);
           // dispatch(setLoading())
        }
    };

    return (
        <Jumbotron>
            <Container fluid>
                <Row>
                    <Col xs={12} xl={5}>
                        <TextComponent hideText = {false} substep = {substep}/>
                    </Col>
                    <Col xs={12} xl={7}>
                        <Hum substep = {substep} />

                        <SoundSelectOptions substep = {substep} selected = {selected} step = {step}/>

                        <Volume substep = {substep}
                        />
                    </Col>
                </Row >
                <ButtonPanel next = {() => {pushData(); props.next()}} prev = {() => {props.prev()}} isInvalid = {() => isInvalid()}selected = {selected}/>
            </Container>
        </Jumbotron>
    )
}

export default SoundscapeManager;