import React from 'react'
import {Form, Container, Row} from 'react-bootstrap'
import {useSelector, useDispatch} from "react-redux";
import {addOpenAnswer} from "./redux/actions";

/**
 * Serves text box for final questions and feedback
 * @returns {*}
 * @constructor
 */
function Vragen() {

    const substep = useSelector(store => store.substep);
    const dispatch = useDispatch();
    const value = useSelector(store=> store.openAnswers)[substep];

    const textBoxStyle = {
        height: "auto",
        float : "center",
        border: "none",
        borderRadius : '5%',
        backgroundColor : "#EBEEF1",
        boxShadow: "9px 9px 16px rgba(211, 214, 216, 0.6) inset, -9px -9px 16px    rgba(255,255,255, 0.5) inset",
    };

    return (
        <Container fluid className = 'mt-5'>
            <Row>
                <Form.Group controlId="feedback" className = "mx-lg-5 px-lg-5" style = {{width : '100%', float : "center"}} >
                    <Form.Control
                        style = {textBoxStyle}
                        className = "pt-lg-5 px-lg-5"
                        as="textarea"
                        defaultValue = {value == undefined ? '' : value}
                        placeholder= 'Typ hier ...'
                        rows = "15"
                        onChange = {(x) => dispatch(addOpenAnswer(x.target.value, substep))}/>
                </Form.Group>
            </Row>
        </Container>
    )
}

export default Vragen