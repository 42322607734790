export const setAge = (age) => {
    return {
        type: 'AGE',
        payload: age
    }
};
export const setGender = (gender) => {
    return {
        type: 'GENDER',
        payload: gender
    }
};
export const addSensitivity = (situation, answer, type) => {
    return {
        type: 'ADDSENSITIVITY',
        payload: {
            situation: situation,
            answer: answer,
            quesType : type
        }
    }
};
export const setSensitivityQuestion = (number, type) => {
    return {
        type: 'SETQUESTION',
        payload: {
            number: number,
            quesType : type
        }
    }
};
export const setMood = (mood) => {
    return {
        type: 'SETMOOD',
        payload: mood
    }
};