import axios from "axios";
import {process} from "../../common_components/server_connection/server_requests";

export const pushNoiseAnswer = (ID, answers, key, background) => {
    axios.post(window.host + 'basic-questions/', getData(ID, answers, background),
        {headers : {Authorization : key}})
        .then(res => {
            console.log(res);
        }).catch(err => console.log(err))
};

export const pushOutlookQuestions = (id, hopes, opinions, openAnswer, key) => {
   const headers = {Authorization : key} ;
   let data = {ref_id : id,"qu20" : openAnswer};
   Object.keys(opinions).map(x=> (data["qu" + x] = process(opinions[x])));
   Object.keys(hopes).map(x=> (data["qu" + (parseInt(x) + 10)] = process(hopes[x])));
    axios.post(window.host + 'investigation/', data, {headers : headers})
        .then(res => {
            console.log(res);
        })
};

export const getData = (id, answers, background) => {
    const data = {ref_id : id, curr_bg_noise : parseInt(background)};
    Object.keys(answers).map(x => (data["qu" + x] = mapAnswer(answers[x])));
    return data;
};


export const mapAnswer = (answer) => {
    if(Array.isArray(answer)) {
        return parseInt(mapMultipleChoice(answer))
    }else if(Number.isNaN(parseInt(answer))) {
        return answer
    }else return parseInt(answer);
};

export const mapMultipleChoice = (answer) => {
    let pattern = "";
    answer.sort().map(x=>(pattern = pattern + x));
    return pattern === "" ? -1 : pattern;
};