const options =
    [
        {
            id : 1,
            text : 'zeer ongewenst'
        },
        {
            id : 2,
            text : 'ongewenst'
        },
        {
            id : 3,
            text : 'enigszins ongewenst'
        },
        {
            id : 4,
            text : 'neutraal'
        },
        {
            id : 5,
            text : 'enigszins wenselijk'
        },
        {
            id : 6,
            text : 'wenselijk'
        },
        {
            id : 7,
            text :'zeer wenselijk'
        }

    ];

export default options;