const image = [
  {
    "id" : "neutral",
    "url" : "neutral.png",
    "width" : "48",
    "alt" : "neutral"
  },
   {
    "id" : "excited-lively",
    "url" : "excited-lively.png",
     "width" : "155",
     "alt" : "Excited/ Lively"
  },
  {
    "id" : "tense-nervous",
    "url" : "tense-nervous.png",
    "width" : "130",
    "alt" : "Tense/ Nervous"
  },
  {
    "id" : "irritated-annoyed",
    "url" : "irritated-annoyed.png",
    "width" : "110",
    "alt" : "Irritated/ Annoyed"
  },
  {
    "id" : "bored-weary",
    "url" : "bored-weary.png",
    "width" : "125",
    "alt" : "Bored/ Weary"
  },
  {
    "id" : "gloomy-sad",
    "url" : "gloomy-sad.png",
    "width" : "115",
    "alt" : "Gloomy/ Sad"
  },
  {
    "id" : "calm-serene",
    "url" : "calm-serene.png",
    "width" : "115",
    "alt" : "Calm/ Serene"
  },

  {
    "id" : "relaxed-carefree",
    "url" : "relaxed-carefree.png",
    "width" : "150",
    "alt" : "Relaxed/ Carefree"
  },
    {
    "id" : "cheerful-happy",
    "url" : "cheerful-happy.png",
      "width" : "165",
      "alt" : "Cheerful / Happy"
  }
];

export default image;
