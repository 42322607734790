import {step, substep, refID, sensitivity, table_question, soundscapeID, loading, random} from './reducers'
import {combineReducers} from 'redux'
import {mood, welcome_data} from "../components/welcome_step/redux/reducers";
import {noiseQues} from "../components/mood_step/redux/reducers";
import {selectedSounds, hum} from "../components/soundscape_step/redux/reducers";
import {
    desirability,
    gedrag,
    verbal,
    wordCount,
    situation,
    breakInvalid,
    desirabilityCount,
    accuracy
} from "../components/user_response_step/redux/reducers";
import {info, openAnswers} from "../components/information_step/redux/reducers";
import {email, password, key} from "../components/login_step/redux/reducers";

const rootReducer = combineReducers({
    email, password, key, soundscapeID, loading, random,
    step, substep, welcome_data, mood, refID, hum,
    selectedSounds, accuracy, desirability, gedrag, table_question, desirabilityCount,
    sensitivity, verbal, noiseQues, wordCount, situation, breakInvalid, info, openAnswers
});

export default rootReducer;