export const addSound = (sound, soundType) => {
    return {
        type: 'ADDSOUND',
        payload: {
            soundType: soundType,
            sound: sound
        }
    }
};
export const removeSound = (sound, soundType) => {
    return {
        type: 'REMOVESOUND',
        payload: {
            soundType: soundType,
            sound: sound
        }
    }
};
export const changeVolume = (sound, volume, soundType) => {
    return {
        type: 'CHANGEVOLUME',
        payload: {
            sound: sound,
            volume: volume,
            soundType: soundType
        }
    }
};

export const addHum = (volume) => {
    return {
        type : 'ADDHUMVOLUME',
        payload : volume
    }
};