import React from 'react'
import fields from './resource/contact_fields'
import BasicInformation from "./BasicInformation";
import {Row, Container} from "react-bootstrap";
import {useSelector} from "react-redux";

function Contact(props) {

    return (
        <Container className = "py-xl-5 my-xl-5 mx-auto">

                <BasicInformation fields = {fields} substep = "contact"/>

        </Container>
    )
}

export default Contact;