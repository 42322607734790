import React from 'react'
import {Row, Col, Form} from 'react-bootstrap'
import {useSelector, useDispatch} from "react-redux";
import {addSensitivity} from "../welcome_step/redux/actions";
import '../welcome_step/sensitivity.css'

/**
 * Displays rows of vraag nummer, vraag tekst en radio options
 * @param props : vraag nummber, vraag tekst
 * @constructor
 */
function OptionRow(props) {

    const selected = useSelector(store => store.sensitivity)[props.substep][props.id];

    const dispatch = useDispatch();

    const bedragOptions =  {1 :'Minder dan \u20ac1.000',
             2 :'\u20ac1.000 - \u20ac1.500',
             3 :'\u20ac1.500 - \u20ac2.000',
              4: '\u20ac2.000 - \u20ac3.000',
             5 :'\u20ac3.000 - \u20ac4.000',
             6 :'\u20ac4.000 - \u20ac5.000',
             7 :'Als iemand het geluid voor mij kan elimineren, kan het me niet schelen hoeveel het kost'};

    const radioStyle = {
        width : '15px',
        height : '15px',
    };

    const getInputRadio = (x) => {
        return(
            <Col className = "d-none d-md-block">
                <Row style = {{height : '100%'}}>
                    <input
                       // style = {radioStyle}
                        className="radioOption mx-auto my-auto"
                        type="radio"
                        value= {x}
                        checked={x===selected}
                        onChange = {() => changeSelection(x)}
                    />
                </Row>
            </Col>
        )
    };

    const getInputRadioAdj = (x) => {
        return (
            <Col className = "d-block d-md-none ">
                <p className = "optionText" style = {{fontWeight : 'bold' , fontSize : '4vw', display : 'table'}}>
                    <input
                        className = 'radioOption'
                        style = {{marginRight : '20px', display : 'table-cell', verticalAlign : 'middle'}}
                        type="radio"
                        value= {x}
                        checked={x===selected}
                        onChange = {() => changeSelection(x)}
                    />
                    {x}
                </p>

            </Col>
        )
    };

    const changeSelection = (x) => {
        if(x!==selected) {
            dispatch(addSensitivity(props.id, x, props.substep))
        }
    };

    const rowStyle = {
        background : props.id % 2 === 0 || (props.substep === 'questions' && props.id === '7') ? ' #EBEEF1' : '#D6DCE24B'
    };

    const formStyle = {
        height: "3.7em",
        float : "center",
        border: "none",
        textAlign : 'center',
        backgroundColor : "#EBEEF1",
        boxShadow: "9px 9px 16px rgba(211, 214, 216, 0.6) inset, -9px -9px 16px    rgba(255,255,255, 0.5) inset",
        textAlignLast : 'center',
        width : '80%',
        marginRight : 'auto',
        marginLeft : 'auto'
    };

    return (
        <Row style = {rowStyle}>
            <Col md ={5}>
                <Row className = {props.substep === 'opinions' ? "mr-md-5 px-md-4 my-3 questionHeader" :  "mr-md-5 px-md-4 my-4 questionHeader"}>
                    <Col md={1}>{props.substep === 'hopes' ? parseInt(props.id) + 10 :props.id}.</Col>
                    <Col md={11}>{props.text}</Col>
                </Row>
            </Col>
            <Col md={7}>
                {props.substep === 'questions' && props.id === '7' ?
                    <Row style = {{height : '100%'}} xs={1}>
                        <Form>
                            <Form.Group className = "my-3" controlId= {props.id} name= {props.text}>
                                <Form.Control
                                    defaultValue=''
                                    as="select"
                                    className="my-3 rounded-pill"
                                    style={formStyle}
                                    type="Number"
                                    placeholder="Bedrag"
                                    value={selected}
                                    onChange={(x) => dispatch(addSensitivity(props.id, x.target.value, props.substep))}>
                                    {selected == undefined ? <option> Bedrag per jaar  </option> : <option disabled> Bedrag per jaar</option>}
                                    { Object.keys(bedragOptions).map(x=><option value = {x}> {bedragOptions[x]}</option>)}
                                </Form.Control>
                            </Form.Group>
                        </Form>
                    </Row>:
                    <Row style = {{height : '100%'}} xs={1} md ={6}>
                        {props.options.map(x=>getInputRadio(x))}
                        {props.options.map(x=>getInputRadioAdj(x))}
                    </Row>}
            </Col>
        </Row>
    )
}

export default OptionRow;