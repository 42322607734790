import axios from "axios";
import {process} from "../../common_components/server_connection/server_requests";

export const pushInformation = (id, info, openAnswer, ques, key) => {
    let data = {ref_id : id, feedback : openAnswer};
    axios.post(window.host + 'supporting-information/',
        getData(data, ques, info),
        {headers : {
                Authorization : key
            }})
        .then(res => {
            console.log(res);
        }).catch(err => console.log("Supporting info error"))
};

const getData = (data, ques, info) => {
    Object.keys(ques).map(x=>(data["qu" + x] = process(ques[x])));
    Object.keys(info).filter(x=>(parseInt(x) !== 4)).map(x=>
        (data[x] = info[x])
    );
    return data;
};

