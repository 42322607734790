import React from 'react'

function ProgressBar(props) {

    const progressStyle = {
        float : "right",
        marginTop : '7px',
        width : "25%",
        height : '12px',
        backgroundColor : '#A3B1C6',
        border: '1.5px solid rgba(250, 250, 250, 1)',
        borderRadius : '50px'
    };

    const fillerStyle = {
        width : `${props.percentage}%`,
        height : '100%',
        backgroundColor : '#E0E5EC',
        borderRadius : 'inherit'
    };

    return (
        <div className = "progress-bar mx-md-5" style = {progressStyle}>
            <div className = "filler" style = {fillerStyle}>
            </div>
        </div>

    )
}

export default ProgressBar;