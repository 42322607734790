/**
 * Dictionary for noise icons
 * Contains information about sound name, image and audio source
 * Combined catalogue for all noises
 */


import {klok, wasmachine, computer_ventilator, douche, licht_ruis,
    friezer, afzuigkap, airco, fornuis} from '../soundscape_step/resource/audio/indoor_sounds/index'
import {klok15, wasmachine15, computer_ventilator15, douche15, licht_ruis15,
    friezer15, afzuigkap15, airco15, fornuis15} from '../soundscape_step/resource/audio/indoor_15/index'

import {auto_car, bel, brommer, buren, grasmaaier, helicopter,
    metro, plane_taking_off, seagul, siren, snelweg, street_cleaner,
    terras, tram, trein, vliegtuig_in_de_lucht, vuilniswagen, wind, windmolen,
    basisschool, motor, electric_hum, bus, construction, drilling} from "../soundscape_step/resource/audio/outdoor_sounds/index";
import {auto_car_denoize, bel_denoize, brommer_denoize, buren_denoize, grasmaaier_denoize, helicopter_denoize,
    metro_denoize, plane_taking_off_denoize, seagul_denoize, siren_denoize, snelweg_denoize, street_cleaner_denoize,
    terras_denoize, tram_denoize, trein_denoize, vliegtuig_in_de_lucht_denoize, vuilniswagen_denoize, wind_denoize, windmolen_denoize,
    basisschool_denoize, motor_denoize, electric_hum_denoize, bus_denoize, construction_denoize, drilling_denoize} from "../soundscape_step/resource/audio/outdoor_denoize/index";
import {auto_car15, bel15, brommer15, buren15, grasmaaier15, helicopter15,
    metro15, plane_taking_off15, seagul15, siren15, snelweg15, street_cleaner15,
    terras15, tram15, trein15, vliegtuig_in_de_lucht15, vuilniswagen15, wind15, windmolen15,
    basisschool15, motor15, electric_hum15, bus15, construction15, drilling15} from "../soundscape_step/resource/audio/outdoor_15/index";

import {bird, fire, wave, creek, rain } from '../user_response_step/resource/audio/nature_sounds/index'
import {bird_denoize, fire_denoize, wave_denoize, creek_denoize, rain_denoize } from '../soundscape_step/resource/audio/nature_denoize/index'

const noise_icon = {
    Auto : {
        name : 'Auto',
        image: require('../resource/img/auto.png'),
        highres : require('../resource/img/highres/auto.png'),
        audio:  auto_car,
        audio15 : auto_car15,
        audio_denoize : auto_car_denoize
    },

    ['School/kinderen die spelen'] : {
        name : "School/kinderen die spelen",
        image : require('../resource/img/basisschool.png'),
        highres : require('../resource/img/highres/basisschool.png'),
        audio: basisschool,
        audio15 : basisschool15,
        audio_denoize :basisschool_denoize
    },
    Brommer : {
        name : "Brommer",
        image : require('../resource/img/brommer.png'),
        highres : require('../resource/img/highres/brommer.png'),
        audio: brommer,
        audio15 : brommer15,
        audio_denoize : brommer_denoize
    },
    Buren : {
        name : "Buren",
        image : require('../resource/img/buren.png'),
        highres : require('../resource/img/highres/buren.png'),
        audio: buren,
        audio15 : buren15,
        audio_denoize : buren_denoize
    },
    Helicopter : {
        name : "Helicopter",
        image : require('../resource/img/helicopter.png'),
        highres : require('../resource/img/highres/helicopter.png'),
        audio: helicopter,
        audio15 : helicopter15,
        audio_denoize : helicopter_denoize
    },
    Metro :{
        name : "Metro",
        image : require('../resource/img/metro.png'),
        highres : require('../resource/img/highres/metro.png'),
        audio: metro,
        audio15 : metro15,
        audio_denoize : metro_denoize
    },
    Meeuwen : {
        name : "Meeuwen",
        image : require('../resource/img/seagul.png'),
        highres : require('../resource/img/highres/seagul.png'),
        audio: seagul,
        audio15 : seagul15,
        audio_denoize : seagul_denoize
    },
    Motor :{
        name : "Motor",
        image : require('../resource/img/motor.png'),
        highres : require('../resource/img/highres/motor.png'),
        audio: motor,
        audio15 : motor15,
        audio_denoize : motor_denoize
    },
    Schuurmachine : {
        name : 'Schuurmachine',
        image : require('../resource/img/heien.png'),
        highres : require('../resource/img/highres/heien.png'),
        audio: drilling,
        audio15 : drilling15,
        audio_denoize : drilling_denoize
    },
    Terras : {
        name : 'Terras',
        image : require('../resource/img/terras.png'),
        highres : require('../resource/img/highres/terras.png'),
        audio: terras,
        audio15 : terras15,
        audio_denoize : terras_denoize
    },
    Grasmaaier : {
        name : 'Grasmaaier',
        image : require('../resource/img/grasmaaier.png'),
        highres : require('../resource/img/highres/grasmaaier.png'),
        audio: grasmaaier,
        audio15 : grasmaaier15,
        audio_denoize : grasmaaier_denoize
    },
    ['Vliegtuig in de lucht'] : {
        name : 'Vliegtuig in de lucht',
        image : require('../resource/img/vliegtuig_in_de_lucht.png'),
        highres : require('../resource/img/highres/vliegtuig_in_de_lucht.png'),
        audio: vliegtuig_in_de_lucht,
        audio15 : vliegtuig_in_de_lucht15,
        audio_denoize : vliegtuig_in_de_lucht_denoize
    },

    Snelweg : {
        name : "Snelweg",
        image : require('../resource/img/snelweg.png'),
        highres : require('../resource/img/highres/snelweg.png'),
        audio: snelweg,
        audio15 : snelweg15,
        audio_denoize : snelweg_denoize
    },
    Klokkenspel : {
        name : 'Klokkenspel',
        image : require('../resource/img/bel.png'),
        highres : require('../resource/img/highres/bel.png'),
        audio: bel,
        audio15 : bel15,
        audio_denoize : bel_denoize
    },
    Bouwgeluiden : {
        name : 'Bouwgeluiden',
        image : require('../resource/img/construction.png'),
        highres : require('../resource/img/highres/construction.png'),
        audio: construction,
        audio15 : construction15,
        audio_denoize : construction_denoize
    },
    ['Elektrische brom'] : {
        name : 'Elektrische brom',
        image : require('../resource/img/electric_hum.png'),
        highres : require('../resource/img/highres/electric_hum.png'),
        audio: electric_hum,
        audio15 : electric_hum15,
        audio_denoize : electric_hum_denoize
    },

    Tram : {
        name : "Tram",
        image : require('../resource/img/tram.png'),
        highres : require('../resource/img/highres/tram.png'),
        audio: tram,
        audio15 : tram15,
        audio_denoize : tram_denoize
    },
    Trein : {
        name: "Trein",
        image : require('../resource/img/trein.png'),
        highres : require('../resource/img/highres/trein.png'),
        audio: trein,
        audio15 : trein15,
        audio_denoize : trein_denoize
    },
    Sirene : {
        name: "Sirene",
        image : require('../resource/img/siren.png'),
        highres : require('../resource/img/highres/siren.png'),
        audio: siren,
        audio15 : siren15,
        audio_denoize : siren_denoize
    },
    Wind : {
        name: "Wind",
        image : require('../resource/img/wind.png'),
        highres : require('../resource/img/highres/wind.png'),
        audio: wind,
        audio15 : wind15,
        audio_denoize : wind_denoize
    },
    Straatveger : {
        name: "Straatveger",
        image : require('../resource/img/street_cleaner.png'),
        highres : require('../resource/img/highres/street_cleaner.png'),
        audio: street_cleaner,
        audio15 : street_cleaner15,
        audio_denoize : street_cleaner_denoize
    },
    ['Opstijgend vliegtuig'] : {
        name: "Opstijgend vliegtuig",
        image : require('../resource/img/plane_taking_off.png'),
        highres : require('../resource/img/highres/plane_taking_off.png'),
        audio: plane_taking_off,
        audio15 : plane_taking_off15,
        audio_denoize : plane_taking_off_denoize
    },

    Vrachtwagen : {
        name: "Vrachtwagen",
        image : require('../resource/img/vrachtwagen.png'),
        highres : require('../resource/img/highres/vrachtwagen.png'),
        audio: bus,
        audio15 : bus15,
        audio_denoize : bus_denoize
    },
    Vuilniswagen : {
        name: "Vuilniswagen",
        image : require('../resource/img/vuilniswagen.png'),
        highres : require('../resource/img/highres/vuilniswagen.png'),
        audio: vuilniswagen,
        audio15 : vuilniswagen15,
        audio_denoize : vuilniswagen_denoize
    },
    Windmolen : {
        name: "Windmolen",
        image : require('../resource/img/windmolen.png'),
        highres : require('../resource/img/highres/windmolen.png'),
        audio: windmolen,
        audio15 : windmolen15,
        audio_denoize : windmolen_denoize
    },

    /**
     *Indoor sounds - denoize soundscape is same as regular
     */
    Airco : {
        name: 'Airco',
        image: require('../resource/img/airco.png'),
        highres : require('../resource/img/highres/airco.png'),
        audio: airco,
        audio15 : airco15,
        audio_denoize : airco
    },
    Wasmachine : {
        name: "Wasmachine",
        image : require('../resource/img/wasmachine.png'),
        highres : require('../resource/img/highres/wasmachine.png'),
        audio: wasmachine,
        audio15 : wasmachine15,
        audio_denoize : wasmachine
    },
    Fornuis : {
        name: "Fornuis",
        image : require('../resource/img/fornuis.png'),
        highres : require('../resource/img/highres/fornuis.png'),
        audio: fornuis,
        audio15 : fornuis15,
        audio_denoize : fornuis
    },
    ['Computerventilator'] : {
        name: 'Computerventilator',
        image : require('../resource/img/computer_ventilator.png'),
        highres : require('../resource/img/highres/computer_ventilator.png'),
        audio: computer_ventilator,
        audio15 : computer_ventilator15,
        audio_denoize : computer_ventilator
    },
    ['TL buis ruis'] : {
        name: "TL buis ruis",
        image : require('../resource/img/licht_ruis.png'),
        highres : require('../resource/img/highres/licht_ruis.png'),
        audio: licht_ruis,
        audio15 : licht_ruis15,
        audio_denoize : licht_ruis
    },
    'Afzuigkap' : {
        name: "Afzuigkap",
        image : require('../resource/img/afzuigkap.png'),
        highres : require('../resource/img/highres/afzuigkap.png'),
        audio: afzuigkap,
        audio15 : afzuigkap15,
        audio_denoize : afzuigkap
    },
    'Douche' : {
        name: "Douche",
        image : require('../resource/img/douche.png'),
        highres : require('../resource/img/highres/douche.png'),
        audio: douche,
        audio15 : douche15,
        audio_denoize : douche
    },
    'Klok' : {
        name: "Klok",
        image : require('../resource/img/klok.png'),
        highres : require('../resource/img/highres/klok.png'),
        audio: klok,
        audio15 : klok15,
        audio_denoize : klok
    },

    'Vriezer' : {
        name: "Vriezer",
        image : require('../resource/img/friezer.png'),
        highres : require('../resource/img/highres/friezer.png'),
        audio: friezer,
        audio15 : friezer15,
        audio_denoize : friezer
    },

    //nature sounds
    'Bird' : {
        name : "Bird",
        image : require('../resource/img/highres/bird.png'),
        highres : require('../resource/img/highres/bird.png'),
        audio: bird_denoize,
        audio_15 : bird,
        audio_denoize : bird_denoize
    },

    'Creek' : {
        name : "Creek",
        image : require('../resource/img/highres/creek.png'),
        highres : require('../resource/img/highres/creek.png'),
        audio: creek_denoize,
        audio_15 : creek,
         audio_denoize : creek_denoize
    },

    "Fire" : {
        name : "Fire",
        image : require('../resource/img/highres/fire.png'),
        highres : require('../resource/img/highres/fire.png'),
        audio: fire_denoize,
        audio_15 : fire,
        audio_denoize : fire_denoize
    },

    "Rain" : {
        name : "Rain",
        image : require('../resource/img/highres/rain.png'),
        highres : require('../resource/img/highres/rain.png'),
        audio : rain_denoize,
        audio_15 : rain,
        audio_denoize : rain_denoize
    },

    "Wave" : {
        name : "Wave",
        image : require('../resource/img/highres/wave.png'),
        highres : require('../resource/img/highres/wave.png'),
        audio : wave_denoize,
        audio_15 : wave,
        audio_denoize : wave_denoize
    }

};

export default noise_icon;
