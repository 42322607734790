export const setEmail = (email) => {
    return {
        type : 'SETEMAIL',
        payload : email
    }
};

export const setStepAfterLogin = () => {
    return {
        type : 'LOGIN'
    }
};

export const setPassword = (password) => {
    return {
        type : 'SETPASSWORD',
        payload : password
    }
};

export const setRefID = (id) => {
    return {
        type : 'SETREFID',
        payload : id
    }
};

export const setKey = (key) => {
    return {
        type : 'SETKEY',
        payload : key
    }
};