import React from 'react'
import {Col, Container, Row} from "react-bootstrap";
import {useSelector, useDispatch} from "react-redux";
import {changeAccuracy} from "./redux/actions";

function RealityCheck(props) {

    const options = ['Zeer inaccuraat', 'Inaccuraat', 'Redelijk inaccuraat', 'Neutraal', 'Redelijk accuraat', 'Accuraat', 'Zeer accuraat'];
    const dispatch = useDispatch();
    const selected = useSelector(store => store.accuracy);

    if(props.substep !== 'reality_check') {
        return null;
    }

    const radioStyle = {
        width : '25px',
        height : '25px',
        border : 'none',
        backgroundColor : ' #A3B1C6',
        marginRight : '20px'
    };

    const labelStyle = {
        width : '100%',
        textAlign: 'left',
        color: '#393E46',
        opacity: '0.9',
        marginRight : 'auto',
        marginLeft : 'auto'
    };

    const getOption = (x) => {
        return (
            <p style = {labelStyle}>
                <input style = {radioStyle}
                       type="radio"
                       value= {x}
                       checked={x===selected}
                       onChange = {(x) => dispatch(changeAccuracy(x.target.value))}
                />  {x}
            </p>)

    };

    return (
        <Container fluid className = "mx-auto containerStyle widthControl">
            <Row xs={1}>
                {options.map(x=><Col className = "mt-2"> {getOption(x)}</Col>)}
            </Row>
        </Container>
    )
}

export default RealityCheck;