import React from 'react'
import {Container, Row} from 'react-bootstrap'
import optimizedImage from './resource/img/hum.png'
import ImageFilter from "react-image-filter";
import ReactHowler from "react-howler";
import hum from './resource/audio/Hum.wav'
import {useSelector, useDispatch} from "react-redux";
import {addHum} from "./redux/actions";

function Hum(props) {
    const dispatch = useDispatch();
    const volume =useSelector(store => store.hum);

    if(props.substep !== 'hum') {
        return null;
    }

    const white = [235, 238, 241];
    const blue = [0, 101, 255];
    const imageStyle = {
        height: 'auto',
        width: '60%',
        // objectFit: 'contain'
    };


    return (
        <Container fluid >
            <Row className = "my-5" >
                <div style = {{display : 'block', textAlign : 'center', width : '100%'}}>
                    <img src = {optimizedImage} style = {imageStyle}/>
                </div>
            </Row>

            <Row className = "my-5 mx-xs-1 mx-md-5 hum">
                <input  value = {volume}
                        style = {{position : 'relative', float : 'bottom'}}
                        type="range" min="1" max="100" className = 'slider'
                        onChange={( x ) => dispatch(addHum(x.target.value))}/>
            </Row>

            <ReactHowler
                src = {hum}
                //src = {'http://goldfirestudios.com/proj/howlerjs/sound.ogg'}
                playing = {true}
                loop = {true}
                volume = {volume/100}
            />

        </Container>
    )
}

export default Hum;