import React from 'react'
import {Container, Form, Row, Col} from "react-bootstrap";
import {useDispatch, useSelector} from "react-redux";
import {setInfo} from "./redux/actions";

/**
 * First page of information step
 * @param props : fields : contains names of input fields for the user
 * @constructor
 */
function BasicInformation(props) {
    const fields = props.fields;

    const dispatch = useDispatch();
    const answers = useSelector(store => store.info);

    const formStyle = {
        height: "3.7em",
        float : "center",
        border: "none",
        textAlign : 'center',
        backgroundColor : "#EBEEF1",
        boxShadow: "9px 9px 16px rgba(211, 214, 216, 0.6) inset, -9px -9px 16px    rgba(255,255,255, 0.5) inset",
        textAlignLast : 'center'
    };

    const radioStyle = {
        width : '25px',
        height : '25px',
        border : 'none',
        backgroundColor : ' #A3B1C6',
        display : 'table-cell',
        verticalAlign : 'middle',
        marginRight : '15px'
    };

    const textStyle = {
        textAlign : 'center',
        fontWeight : 'bold',
        marginLeft : '3px'
    };

    const getOptionForm = (field) => {
        return (
            <Form.Control className="my-3 mx-auto rounded-pill userdata"
                          style={formStyle}
                          as="select"
                          value = {answers[field.id]}
                          onChange={(x) => dispatch(setInfo(x.target.value, field.id, props.substep))}>
                {answers[field.id] == '' ? <option> {field.text} </option>: <option disabled> {field.text}</option>}
                {field.options.map(x=><option value = {x.id}> {x.text}</option>)}
            </Form.Control>
        )
    };

    const getDataForm = (field) => {
        return (
            <Form.Control
                defaultValue=''
                className="my-3 mx-auto rounded-pill userdata"
                style={formStyle}
                placeholder={field.text}
                value={answers[field.id]}
                onChange={(x) => {if(x.target.value.length <= field.limit && checkNumber(field, x.target.value)) dispatch(setInfo(x.target.value, field.id, props.substep))}}/>
        )
    };

    const checkNumber = (field, value) => {
        if(field.type === 'Number') {
            return value.length === 0 || Number.isInteger(parseInt(value))
        }
        else return true
    };

    const changeSelection = (x) => {
        if(answers[x] === true) {
            dispatch(setInfo(false, x, props.substep))
        } else {
            dispatch(setInfo(true, x, props.substep))
        }
    };

    const getRadio = (field) => {
        return (
            <Row className = 'userdata mx-auto' style = {{width : '100%', display : 'block', textAlign : 'center'}}>
                <p style = {{...textStyle, marginRight : 'auto', marginLeft : 'auto' }} className = "optionText">
                    <input style = {radioStyle}
                           type="radio"
                           checked={answers[field.id]}
                           onClick = {() => changeSelection(field.id)}
                    />
                    {field.text}
                </p>
            </Row>

        )
    };

    const getFormRow = (field) => {
        return (
            <Row>
                <Form className = "mx-auto" style = {{width : '100%'}}>
                    <Form.Group className = "my-3 mx-auto" controlId= {field.id} name= {field.text}>
                        {field.type === 'options' ? getOptionForm(field) : field.type === 'Radio'? getRadio(field) : getDataForm(field)}
                    </Form.Group>
                </Form>
            </Row>
        )
    };

    return (
        <Container fluid>
            {fields.map(x => getFormRow(x))}
        </Container>
    )
}

export default BasicInformation;