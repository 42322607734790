import React, {useState} from 'react'
import ImageFilter from "react-image-filter";
import {useDispatch} from "react-redux";
import {Row} from 'react-bootstrap'
import ReactHowler from 'react-howler'
import {addSound, removeSound} from "./redux/actions";

/**
 * Creates icons for noise sources and manages clicking and hover events
 * @param props name : name of sound, imageLink : source of image, audio : source of sound
 */
function Icon (props) {
    const selected = Object.keys(props.selected);
    const dispatch = useDispatch();

    const [include, setInclude] = useState(selected.includes(props.name));
    const [play, setPlay] = useState(false);

    const soundType = props.step === 3 && props.substep === 'outdoor_sound_select' ?
        'initialSounds' : 'indoorSounds';

    const white = [255, 255, 255];
    const blue = [0, 101, 255];
    /**
     * When user clicks an icon :
     * If icon was already selected, unselect it and remove from database
     * If icon was not already selected, select it and add to database
     * @param event
     */
    const handleClick = event => {
        if (include) {
            setInclude(false);
            dispatch(removeSound(props.name, soundType))
        } else {
            setInclude(true);
            dispatch(addSound(props.name, soundType))
        }
    };


    const imageStyle = {
        border: 'none',
        boxShadow: "6px 6px 6px rgba(163,177,198, 0.6), -6px -6px 6px    rgba(255,255,255, 0.5) ",
        borderRadius: '15px',
        height : '10vh',
        display : 'block',
        textAlign : 'center',
        cursor: 'pointer',
        padding: '15%',
        background : include ? '#0065FF' : '#EBEEF1'
    };

    return (
        <div onClick={() =>  handleClick()} onMouseOver={() => setPlay(true)} onMouseOut = {() => setPlay(false)}>
            <ImageFilter
                style = {imageStyle}
                preserveAspectRatio={'contain'}
                image = {props.imageLink}
                filter={'duotone'}
                colorOne={include ? white : blue}
                colorTwo={include ? blue : white}
                />
            <ReactHowler
                src = {props.audio}
                playing = {play}
                volume = {1}
            />
        </div>

    )

}

export default Icon;