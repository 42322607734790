import React, {useState} from 'react'
import LoginImage from './resource/img/login.png'
import {Col, Container, Form, Image, Jumbotron, Row} from "react-bootstrap";
import {setEmail, setPassword} from "./redux/actions";
import {useSelector, useDispatch} from "react-redux";
import {setRefID, setKey, setStepAfterLogin} from "./redux/actions";
import axios from 'axios'

function Login (props) {
    const dispatch = useDispatch();
    const email = useSelector(store => store.email);
    const password = useSelector(store => store.password);
    const [invalid, setInvalid] = useState(false);
    const [checked, toggle] = useState(false);

    if(props.step !== 0) {
        return null;
    }

       const radioStyle = {
        width : '25px',
        height : '25px',
        border : 'none',
        backgroundColor : ' #A3B1C6',
        display : 'table-cell',
        verticalAlign : 'middle'
    };

    const textStyle = {
        textAlign : 'left',
        fontSize : '1.8vh'
    };


    const formStyle = {
        height: "3.7em",
        border: "none",
        textAlign : 'center',
        backgroundColor : "#EBEEF1",
        boxShadow: "9px 9px 16px rgba(211, 214, 216, 0.6) inset, -9px -9px 16px    rgba(255,255,255, 0.5) inset",
        textAlignLast : 'center',
        width : '100%'
    };

    const isInvalid = () => {
        return email === '' || password === '' || !checked
    };

    const login = () => {
        axios.post(window.host + 'login/',{email_address : email, key : password})
            .then(res => {
                if(res.status === 202) {
                    dispatch(setRefID(res.data.ref_id));
                    dispatch(setKey("TOKEN "+res.data.key));
                    dispatch(setStepAfterLogin())
                } else setInvalid(true);
            }).catch(error => {
            setInvalid(true)
        });
    };

    return (
        <Jumbotron>
            <Container fluid>
                <Row>
                    <Col xs={12} xl={6}>
                        <Image style ={{width:"100%", height: "100%"}} src= {LoginImage} fluid rounded />
                    </Col>
                    <Col  xs = {0} xl = {1}/>
                    <Col xs={12} xl = {4}>
                        <Row>
                            <h2>Login</h2>
                        </Row>
                        <Row className = "my-3">
                            <p> Vul de inlognaam en wachtwoord in die je via de mail hebt ontvangen, druk vervolgens op start om het onderzoek te starten.</p>
                        </Row>
                        <Row className = "my-3">
                            <Form className = "mx-auto" style = {{width : '100%'}}>
                                <Form.Group className = "my-3 mx-auto"controlId= {'email'} name= {'email'}>
                                    <Form.Control
                                        defaultValue=''
                                        className="my-3 mx-auto rounded-pill"
                                        style={formStyle}
                                        placeholder='Inlognaam'
                                        value = {email}
                                        onChange = {(x) => dispatch(setEmail(x.target.value))}/>
                                </Form.Group>
                            </Form>
                            <Form className = "mx-auto" style = {{width : '100%'}}>
                                <Form.Group className = "my-3 mx-auto"controlId= {'email'} name= {'email'}>
                                    <Form.Control
                                        type = "password"
                                        defaultValue=''
                                        className="my-3 mx-auto rounded-pill"
                                        style={formStyle}
                                        value = {password}
                                        placeholder='Wachtwoord'
                                        onChange = {(x) => dispatch(setPassword(x.target.value))}/>
                                </Form.Group>

                                <Form.Group controlId="formBasicCheckbox" >
                                    <Row className = 'mt-5 mx-auto'>
                                        <Col xs={1}>
                                            <input style = {radioStyle}
                                                   type="radio"
                                                   checked={checked}
                                                   onClick = {() => toggle(!checked)}
                                            />
                                        </Col>
                                        <Col xs={11} >
                                            <label style = {textStyle} className = "optionText">
                                                Door het verzenden van deze informatie ga ik akkoord met de
                                                <a href={"https://denoize.com/privacy-policy-nederlands/"} rel="noopener noreferrer" target = "_blank"> privacystatement </a>
                                                en het daarbij horende gebruik van data.
                                            </label>
                                        </Col>
                                    </Row>
                                </Form.Group>
                            </Form>

                        </Row>
                        <Row style = {{width : '100%', textAlign : 'center'}}>
                            {invalid ? <p style = {{width : '100%', textAlign : 'center', color : 'red'}}> Incorrect credentials </p> : null}
                        </Row>
                        <Row>
                            <button
                                style = {{opacity : isInvalid() ? '30%' : '100%'}}
                                disabled={isInvalid()}
                                onClick = {() => {login()}}
                                className = "mt-4 mx-auto oval">Volgende
                            </button>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </Jumbotron>
    )
}

export default Login;