import React, {useEffect, useState} from 'react'
import {Container, Row} from "react-bootstrap";
import ButtonPanel from "../common_components/ButtonPanel";
import Iframe from "react-iframe";
import {useSelector} from "react-redux";
import {activate} from "./redux/actions";

function CognitiveScreen(props) {
    const step = useSelector(store => store.step);
    const ref_id = useSelector(store => store.refID);
    const type = (step === 4) ? 1 : 2
    const url = "https://stroopexperiment.herokuapp.com?" + "ref_id="+ref_id+"&type=" + type;
    const [disabled, enable] = useState(true)

    useEffect(() => {
        const timeout = setTimeout(() => {
            enable(false)
        }, 2000);


        return () => {
            clearTimeout(timeout);
        };
    },[props.step]);

    return (
        <div className="never">
            <Row style = {{width : '100%'}}>
                <Iframe url={url}
                        height = "500vh"
                        width = "100%"
                        id="myId"
                        allowFullScreen={true}
                        display="initial"/>
            </Row>
            <ButtonPanel next = {props.next} prev = {props.prev} isInvalid = {() => disabled}/>
        </div>
    );
}

export default CognitiveScreen