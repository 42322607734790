import React from 'react'
import WelcomeScreen from './WelcomeScreen'
import WelcomeData from './WelcomeData'
import {useSelector} from 'react-redux'
import TableQuestion from "../common_components/TableQuestion";
import SoundSensitivityIntro from "./SoundSensitivityIntro";
import WelcomeIntro from "./WelcomeIntro"
import MoodSelect from "./MoodSelect";

/**
 * Manages all components of the welcome step
 * Used to render welcome screen, data form and sound sensitivity questions
 * @returns null if the current step is not 1
 */
function WelcomeManager(props){
        const step = useSelector(state => state.step);
        const substep = useSelector(store => store.substep);

        if ( step!== 1 && !(step===7 && substep === 'questions')) {
            return null
        }

        return (
            <div>
                <WelcomeScreen scroll = {props.scroll} next = {props.next} getID = {props.getID} substep = {props.substep}/>
                <MoodSelect substep = {props.substep} next = {props.next} prev = {props.prev}/>
                <WelcomeIntro scroll = {props.scroll} next = {props.next} substep = {props.substep}/>
                <WelcomeData substep = {props.substep} next = {props.next}/>
                <SoundSensitivityIntro substep = {props.substep} next = {props.next} prev = {props.prev}/>
                <TableQuestion substep = {props.substep} next = {props.next} prev = {props.prev}/>
            </div>
        )

}


export default WelcomeManager;