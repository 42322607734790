import React from 'react'
import {Row, Container} from 'react-bootstrap'
import VolumeSlider from "./VolumeSlider";
import assets from '../data_constant/noise_icon'
import {useSelector} from "react-redux";

function Volume(props) {

    const outdoorSelected = useSelector(store => store.selectedSounds.initialSounds);
    const indoorSelected = useSelector(store => store.selectedSounds.indoorSounds);

    if(props.substep !== 'outdoor_volume' &&
        props.substep !== 'indoor_volume') {
        return null;
    }

    const outdoorKeys = Object.keys(outdoorSelected).sort();
    const indoorKeys = Object.keys(indoorSelected).sort();

    const createSlider = (sound, stype, selected) => {
        const imageLink =assets[sound].image;
        const audio = assets[sound].audio15;
        return (
            <Row key = {sound} className = "my-5">
                <VolumeSlider image = {imageLink} sound = {sound} audio = {audio} selected = {selected} soundType = {stype}/>
            </Row>)
    };

    return (
        <Container fluid className = "containerStyle scrollable">
            {props.substep === 'indoor_volume' ? indoorKeys.map((sound) => createSlider(sound, 'indoorSounds', indoorSelected)) : null}
            {props.substep === 'outdoor_volume' || props.substep === 'indoor_volume' ? outdoorKeys.map((sound) => createSlider(sound, 'initialSounds', outdoorSelected)) : null}
        </Container>

    )
}

export default Volume;