export const auto_car15 =  require('./Auto.mp3');
export const bel15 =  require('./Bel.mp3');
export const brommer15 =  require('./Brommer.mp3');
export const buren15 =  require('./Buren.mp3');
export const grasmaaier15 =  require('./Grasmaaier.mp3');
export const helicopter15 =  require('./Helicopter.mp3');
export const metro15 =  require('./Metro.mp3');
export const plane_taking_off15 =  require('./Plane_taking_off.mp3');
export const seagul15 =  require('./Seagul.mp3');
export const siren15 =  require('./Siren.mp3');
export const snelweg15 =  require('./Snelweg.mp3');
export const street_cleaner15 =  require('./Street_cleaner.mp3');
export const terras15 =  require('./Terras.mp3');
export const tram15 =  require('./Tram.mp3');
export const trein15 =  require('./Trein.mp3');
export const vliegtuig_in_de_lucht15 =  require('./Vliegtuig_in_de_lucht.mp3');
export const vuilniswagen15 =  require('./Vuilniswagen.mp3');
export const wind15 =  require('./Wind.mp3');
export const windmolen15 =  require('./Windmolen.mp3');
export const basisschool15 =  require('./Basisschool.mp3');
export const electric_hum15 =  require('./Electric_hum.mp3');
export const motor15 =  require('./Motor.mp3');
export const bus15 =  require('./Buses.mp3');
export const drilling15 =  require('./Drilling.mp3');
export const construction15 =  require('./Construction.mp3');
