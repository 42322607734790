import React from 'react'
import {useSelector, useDispatch} from "react-redux";
import {Col, Container, Jumbotron, Row} from "react-bootstrap";
import TextComponent from "../common_components/TextComponent";
import ButtonPanel from "../common_components/ButtonPanel";
import words from "../data_constant/verbal"
import {changeWordCount, changeSituationCount} from "./redux/actions";
import Break from './Break'
import CognitiveScreen from './CognitiveScreen';
import CognitiveImage from './resource/img/cognitive.png'
import OptimizedImage from './resource/img/optimized.png'
import DesImage from "./resource/img/desirability.png"
import ImageContainer from "../common_components/ImageContainer";
import DesirabilityManager from "./DesirabilityManager";
import GedragManager from "./GedragManager";
import VerbalManager from "./VerbalManager";
import RealityCheck from "./RealityCheck";
import ConfirmImage from "../soundscape_step/resource/img/confirm.png";
import {pushRealityCheck} from "./server_connection/server_request";

/**
 * Manages user responses to soundscapes including verbal test, desirability check and behavious analysis
 * Will be active in step 4 (initial soundscape) , step 7 (optimized soundscape),
 * @param props contains info about step, substep, next button and prev button
 */
function UserResponseManager(props) {

    const wordCount = useSelector(store => store.wordCount);
    const dispatch = useDispatch();
    const breakInvalid = useSelector(store=>store.breakInvalid);
    const realityCheck = useSelector(store => store.accuracy);
    const key = useSelector(store => store.key);
    const soundscapeID = useSelector(store => store.soundscapeID);

    const step = props.step;
    const substep = props.substep;

    if(step !== 4 && step !==5 && step!==6) {
        return null;
    }

    /**
     * Renders another desirability check step if more images are left to be displayed
     * If all desirability ratings have been recorded, do to next substep
     */
    const nextCheck = () => {
        dispatch(changeWordCount(0));
        props.next()
    };

    /**
     * Renders previous desirability check step if images rae left to be displayed
     * Otherwise does to previous substep
     * @param event
     */
    const prevCheck = event => {
        if(!(substep==='verbal_test' && step===4)) {
            dispatch(changeWordCount(words.length - 4));
        }
        props.prev()
    };


    const hideText = () => {
        return substep === 'verbal_test' && wordCount !==0
    };

    const isInvalid = () => {
        if(substep === 'reality_check') {
            return realityCheck === '';
        }
        if(substep === 'new_soundscape') {
            return breakInvalid;
        }
        return false;
    };

    const pushData = () => {
        if(substep === 'reality_check') {
            pushRealityCheck(soundscapeID[step===4 ? 3 : step], realityCheck, key)
        }
    };

    return (
        <>
        {props.substep === 'cognitive_screen' ? <CognitiveScreen next = {props.next} prev = {props.prev}/> :
        <Jumbotron className = {substep==='desirability_check' ? 'never' : null}
        >
            {props.substep === 'desirability_check' ? <DesirabilityManager next = {props.next} prev = {props.prev}/>: null}
            {props.substep === 'cognitive_screen' ? <CognitiveScreen step = {props.step} next = {props.next} prev = {props.prev}/> : null}
            {props.substep === 'behaviour_test' ? <GedragManager step = {props.step} substep = {props.substep} next = {props.next} prev = {props.prev}/> : null}
            {props.substep === 'verbal_test' ? <VerbalManager step = {props.step} next = {props.next} prev = {props.prev} substep = {props.substep}/>: null}
            {props.substep !== 'desirability_check' && props.substep !== 'cognitive_screen' && props.substep !== 'behaviour_test' && props.substep !== 'verbal_test' && props.substep !=='cognitive_game_screen'?
                <Container fluid>
                    <Row >
                        <Col xs={12} xl={5}>
                            <TextComponent
                                hideText={hideText()}
                                substep={substep}/>
                        </Col>
                        <Col xs={12} xl={7}
                        xs={{order : (substep === 'confirm' || substep === 'optimized_soundscape'
                                || substep === 'desirability_intro' || substep === 'cognitive_game') ? 'first' : 'last'}}
                        xl = {{order : 'last'}}>
                            {substep === 'behaviour_test' ? <GedragManager next = {props.next} prev = {props.prev}/> : null}
                            {substep === 'optimized_soundscape' ? <ImageContainer image = {OptimizedImage}/> : null}
                            {substep === 'new_soundscape' ? <Break/> : null}
                            {substep === 'cognitive_game' ? <ImageContainer image = {CognitiveImage}/> : null}
                            {substep === 'desirability_intro' ? <ImageContainer image = {DesImage}/> : null}
                            {substep === 'reality_check' ? <RealityCheck substep = {substep}/> : null}
                            {substep === 'confirm' ? <ImageContainer image = {ConfirmImage}/> : null}
                        </Col>
                    </Row>
                    <ButtonPanel next = {() => {pushData(); nextCheck()}} prev = {() => prevCheck()} isInvalid = {() => isInvalid()}/>
                </Container> : null}

        </Jumbotron> }
            </>
    )
}

export default UserResponseManager;