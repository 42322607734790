/**
 * Questions for opinions about the research
 */
const ques = [
    {
        id : '1',
        text : "Ik geloof dat DeNoize eerlijke bedoelingen heeft om de leefomgeving voor mij te verbeteren."
    },
    {
        id : '2',
        text : "Ik denk niet dat dit onderzoek de leefomgeving voor mij kan verbeteren."
    },
    {
        id : '3',
        text : "Ik denk dat Denoize genoeg kennis heeft om de leefomgeving voor mij te verbeteren."
    },
    {
        id : '4',
        text : "Ik denk dat DeNoize niet genoeg financiële middelen heeft om de leefomgeving voor mij te verbeteren."
    },
    {
        id : '5',
        text : "Ik denk dat DeNoize voldoende draagvlak en samenwerkingen heeft om de leefomgeving voor mij te verbeteren."
    },
    {
        id : '6',
        text : "Ik denk dat mijn inbreng in dit onderzoek serieus genomen zal worden en gebruikt wordt om een oplossing te ontwerpen ter verbetering van mijn leefomgeving."
    },
    {
        id : '7',
        text : 'Ik maak mij zorgen over het gebruik van mijn gegevens als deelnemer aan dit onderzoek.'
    },
    {
        id : '8',
        text : 'Ik vind dat bewoners, lokale overheden en andere relevante organisaties samen een oplossing moeten aandragen om de leefomgeving voor mij te verbeteren.'
    },
    {
        id : '9',
        text : 'Ik vind dat alleen de geluidsproducerende organisaties verantwoordelijk moeten zijn voor het verbeteren van mijn leefomstandigheden.'
    },
    {
        id : '10',
        text : 'Ik denk dat dit onderzoek voor mij een stap is om de leefomgeving te verbeteren.'
    }
];

export default ques;