import React, {useState} from 'react'
import {useSelector} from 'react-redux'
import {Col, Container, Jumbotron, Row, Image} from "react-bootstrap";
import TextComponent from "../common_components/TextComponent";
import ButtonPanel from "../common_components/ButtonPanel";
import Question from "../common_components/Question";
import questions from '../data_constant/mood_questions'
import Vragen from "../information_step/Vragen";
import HopesIntroImage from "./resource/img/hopes.png"
import ImageContainer from "../common_components/ImageContainer";
import TableQuestion from "../common_components/TableQuestion";
import {pushOutlookQuestions} from "./server_connection/server_request";

/**
 * Serves pages for mood step : mood-picker, hopes, expectations
 */
function QuestionManager(props) {
    const step = props.step;
    const substep = props.substep;
    const selectedBasicNoise = useSelector(store => store.noiseQues);
    const [number, setNumber] = useState(0);
    const id = useSelector(store => store.refID);
    const hopes = useSelector(store => store.sensitivity).hopes;
    const opinions = useSelector(store => store.sensitivity).opinions;
    const extraAnswer = useSelector(store => store.openAnswers).extra_answer;
    const key = useSelector(store => store.key);


    if ( step !== 2) {
        return null;
    }

    const isInvalid = () => {
        if(substep === 'basic_noise_ques') {
            const ques = selectedBasicNoise[questions[number].id];
            if (questions[number].type === 'slider' && (ques ==='' || ques == undefined)) {
                return true;
            } else if (questions[number].multiple && !ques.length) {
                return true
            } else if (ques == undefined || ques === '') {
                return true;
            }

        }
         else return false;
    };

    const nextCheck = () => {
        if(substep === 'basic_noise_ques' && number === 0 && selectedBasicNoise[1] === 2) {
            props.next();
            return;
        }
        if(substep === 'basic_noise_ques' && number<questions.length - 1) {
            let x = number + 1;
            if (questions[x].conditional) {
                checkConditionalNext(questions[x], x)
            } else setNumber(number + 1)
        } else {
            if(substep === 'extra_answer') {
                pushOutlookQuestions(id, hopes, opinions, extraAnswer, key)
            }
            props.next();
        }
    };

    const checkConditionalNext = (question, x) => {
        if(!question.conditional) {
            setNumber(x)
        } else {
            if (question.displayOn.includes(selectedBasicNoise[question.dependency])) {
                setNumber(x)
            } else checkConditionalNext(questions[x+1], x + 1)
        }
    };

    const checkConditionalPrev = (question, x) => {
        if(!question.conditional) {
            setNumber(x)
        } else {
            if (question.displayOn.includes(selectedBasicNoise[question.dependency])) {
                setNumber(x)
            } else checkConditionalPrev(questions[x-1], x - 1)
        }
    };

    const prevCheck = () => {
        if(substep === 'basic_noise_ques' && number > 0) {
            let x = number - 1;
            if (questions[x].conditional) {
                console.log(questions[x]);
                checkConditionalPrev(questions[x], x)
            } else setNumber(number - 1)
        } else {
            props.prev();
        }
    };


    const checkHideText = () => {
        if(substep === 'basic_noise_ques' && number >0) {
            return true;
        }
        else return false;
    };


    return (
        <div>
        {substep === 'hopes' || substep === 'opinions' ?
                    <TableQuestion substep = {props.substep} next = {props.next} prev = {props.prev}/> :
        <Jumbotron>
            <Container fluid>
                <Row >
                    <Col xs = {12} xl={5}
                         xs={{order : substep === 'hopes_intro' ? 'last' : 'first'}}
                         xl={{order : 'first'}}>
                        <TextComponent hideText = {checkHideText()} substep={substep}/>
                    </Col>
                    <Col xs={12} xl={7}>
                        {substep === 'extra_answer' ? <Vragen/> : null}
                        {substep === 'hopes_intro' ? <ImageContainer image = {HopesIntroImage}/> : null}
                        {substep === 'basic_noise_ques' ?
                            <Question substep = 'basic_noise_ques'
                                      question = {questions[number]}
                                      number = {number}
                                      setNumber = {setNumber}/>:
                            null}

                    </Col>
                </Row>
                <ButtonPanel
                    next = {() => nextCheck()}
                    prev = {() => prevCheck()}
                    isInvalid = {() => isInvalid()}
                    substep = {props.substep}/>
            </Container>
        </Jumbotron>}
        </div>
    )
}

export default QuestionManager;
