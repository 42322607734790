import React from 'react'
import {useDispatch} from "react-redux";
import {Container, Row} from 'react-bootstrap'
import '../mood_step/demoStyle.css'
import {setMood} from "./redux/actions";

function MoodSelectOptions(props) {

    const ImageData = [
  {
    "id" : "1",
    "url" : "neutral.png",
    "width" : "51",
    "alt" : "neutral"
  },
   {
    "id" : "2",
    "url" : "excited-lively.png",
     "width" : "155",
     "alt" : "Excited/ Lively"
  },
  {
    "id" : "3",
    "url" : "tense-nervous.png",
    "width" : "130",
    "alt" : "Tense/ Nervous"
  },
  {
    "id" : "4",
    "url" : "irritated-annoyed.png",
    "width" : "125",
    "alt" : "Irritated/ Annoyed"
  },
  {
    "id" : "5",
    "url" : "bored-weary.png",
    "width" : "125",
    "alt" : "Bored/ Weary"
  },
  {
    "id" : "6",
    "url" : "gloomy-sad.png",
    "width" : "115",
    "alt" : "Gloomy/ Sad"
  },
  {
    "id" : "7",
    "url" : "calm-serene.png",
    "width" : "115",
    "alt" : "Calm/ Serene"
  },

  {
    "id" : "8",
    "url" : "relaxed-carefree.png",
    "width" : "150",
    "alt" : "Relaxed/ Carefree"
  },
    {
    "id" : "9",
    "url" : "cheerful-happy.png",
      "width" : "185",
      "alt" : "Cheerful / Happy"
  }
];

    const link = './resource/img/' + props.gender + '/';
   // const link = './resource/img/Man/';
    const dispatch = useDispatch();
    const selected = props.mood;

    const setStyle = (id, width) => {
        if (selected === '') {
            return {
                cursor : "pointer",
                width : `${width/1.5}%`,
                height : 'auto',
            }
        } else if (selected === id) {
            return {
                cursor : "pointer",
                width : `${width/1.5}%`,
                height : 'auto',
            }
        } else return {
            width : `${width/1.5}%`,
            cursor : "pointer",
            opacity : 0.3,
            height : 'auto',
        }

    };

    const handleSelect= event => {
        dispatch(setMood(event))
    };


    return (
        <Container fluid >
            <div className = "circle_container " >
                <img className="transparent_square" src="http://i.stack.imgur.com/5Y4F4.jpg" width="2" height="2" style = {{width : '90%', height : '100%'}}/>
                <div className="central_text text-center"   ><img
                    className = "centerImage"
                    onClick={() => handleSelect(ImageData[0].id)}
                    src = {require(`${link + ImageData[0].url}`)}
                    style = {setStyle(ImageData[0].id, ImageData[0].width)}
                    alt = {ImageData[0].alt}
                />
                </div>

                <div className="moon_container moon1"><img className="moon moon1"
                                                           onClick={() => handleSelect(ImageData[1].id)}
                                                           src = {require(`${link + ImageData[1].url}`)}
                                                           alt = {ImageData[1].alt}
                                                           style = {setStyle(ImageData[1].id, ImageData[1].width)}/>
                </div>
                <div className="moon_container moon2"><img className="moon moon2"
                                                           onClick={() => handleSelect(ImageData[2].id)}
                                                           src = {require(`${link + ImageData[2].url}`)}
                                                           alt = {ImageData[2].alt}
                                                           style = {setStyle(ImageData[2].id, ImageData[2].width)}/>
                </div>
                <div className="moon_container moon3"><img className="moon moon3"
                                                           onClick={() => handleSelect(ImageData[3].id)}
                                                           style = {setStyle(ImageData[3].id, ImageData[3].width)}
                                                           alt = {ImageData[3].alt}
                                                           src = {require(`${link + ImageData[3].url}`)}/>
                </div>
                <div className="moon_container moon4"><img className="moon moon4"
                                                           onClick={() => handleSelect(ImageData[4].id)}
                                                           style = {setStyle(ImageData[4].id, ImageData[4].width)}
                                                           alt = {ImageData[4].alt}
                                                           src = {require(`${link + ImageData[4].url}`)}/>
                </div>
                <div className="moon_container moon5"><img className="moon moon5"
                                                           onClick={() => handleSelect(ImageData[5].id)}
                                                           alt = {ImageData[5].alt}
                                                           style = {setStyle(ImageData[5].id, ImageData[5].width)}
                                                           src = {require(`${link + ImageData[5].url}`)}/>
                </div>
                <div className="moon_container moon6"><img className="moon moon6"
                                                           onClick={() => handleSelect(ImageData[6].id)}
                                                           style = {setStyle(ImageData[6].id, ImageData[6].width)}
                                                           alt = {ImageData[6].alt}
                                                           src = {require(`${link + ImageData[6].url}`)}/>
                </div>
                <div className="moon_container moon7"><img className="moon moon7"
                                                           onClick={() => handleSelect(ImageData[7].id)}
                                                           style = {setStyle(ImageData[7].id, ImageData[7].width)}
                                                           alt = {ImageData[7].alt}
                                                           src = {require(`${link + ImageData[7].url}`)}/>
                </div>
                <div className="moon_container moon8"><img className="moon moon8"
                                                           onClick={() => handleSelect(ImageData[8].id)}
                                                           style = {setStyle(ImageData[8].id, ImageData[8].width)}
                                                           alt = {ImageData[8].alt}
                                                           src = {require(`${link + ImageData[8].url}`)}/>
                </div>

            </div>
        </Container>
    );


}


export default MoodSelectOptions;