export const auto_car = require('./Auto.mp3');
export const bel = require('./Bel.mp3');
export const brommer = require('./Brommer.mp3');
export const buren = require('./Buren.mp3');
export const grasmaaier = require('./Grasmaaier.mp3');
export const helicopter = require('./Helicopter.mp3');
export const metro = require('./Metro.mp3');
export const plane_taking_off = require('./Plane_taking_off.mp3');
export const seagul = require('./Seagul.mp3');
export const siren = require('./Siren.mp3');
export const snelweg = require('./Snelweg.mp3');
export const street_cleaner = require('./Street_cleaner.mp3');
export const terras = require('./Terras.mp3');
export const tram = require('./Tram.mp3');
export const trein = require('./Trein.mp3');
export const vliegtuig_in_de_lucht = require('./Vliegtuig_in_de_lucht.mp3');
export const vuilniswagen = require('./Vuilniswagen.mp3');
export const wind = require('./Wind.mp3');
export const windmolen = require('./Windmolen.mp3');
export const basisschool = require('./Basisschool.mp3');
export const electric_hum = require('./Electric_hum.mp3');
export const motor = require('./Motor.mp3');
export const bus = require('./Vrachtwagen.mp3');
export const drilling = require('./Drilling.mp3');
export const construction = require('./Construction.mp3');
