import axios from "axios";

export const pushTableAnswer = (ID, answers, substep, key) => {
    const link = window.host + 'sonore-sensitivity/';
    if(substep === 'sound_sensitivity') {
        axios.post(link,
            getData(ID, answers),
            {headers : {
                Authorization : key
                }}
        )
            .then(res => {
                console.log(res);
            })
    }
};

export const getData = (id, quesArray) => {
    let data = {ref_id : id};
    Object.keys(quesArray).map(x=> (data["qu" + x] = process(quesArray[x])));
    return data;
};

export const process = (answer) => {
    switch (answer) {
        case 'geheel oneens' : return 1;
        case 'oneens' : return 2;
        case  'enigszins oneens' : return 3;
        case 'beetje eens' : return 4;
        case 'enigszins eens' : return 4;
        case 'eens' : return 5;
        case 'geheel eens' : return 6;
        default : return answer;
    }
};