
export const addNoiseQues = (id, answer) => {
    return {
        type: 'ADDNOISEQUES',
        payload: {
            situation: id,
            answer: answer
        }
    }
};

export const addNoiseQuesMultiple = (id, answer) => {
    return {
        type : 'ADDNOISEQUESMULTIPLE',
        payload : {
            situation : id,
            answer : answer,
        }
    }
};

export const removeNoiseQuesMultiple = (id, answer) => {
    return {
        type : 'REMOVEMULTIPLE',
        payload : {
            situation : id,
            answer : answer
        }
    }
};