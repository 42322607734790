/**
 * Information about steps and substeps
 * Used to keep track of page transitions
 */
const steps = {
    0 : {
        substeps : ['login']
    },
    1 : {
        substeps : ['welcome_screen', 'survey_intro', 'welcome_data', 'mood_select',  'sound_sensitivity_intro', 'sound_sensitivity']
    },
    2 : {
        substeps : [ 'basic_noise_ques', 'hopes_intro', 'opinions', 'hopes', 'extra_answer'
        ]
    },
    3 : {
        substeps : ['hum', 'outdoor_sound_select', 'outdoor_volume', 'indoor_sound_select', 'indoor_volume']
    },
    4 : {
        substeps : ['confirm', 'reality_check','verbal_test', 'desirability_intro', 'desirability_check', 'behaviour_test', 'cognitive_game', 'cognitive_screen']
    },
    5 : {
        substeps : ['new_soundscape', 'verbal_test']
    },
    6 : {
        substeps : ['optimized_soundscape', 'verbal_test', 'behaviour_test', 'cognitive_game', 'cognitive_screen']
    },
    7 : {
        substeps : ['information', 'questions', 'volgende_onderzoek', 'contact', 'vragen', 'end']
    }
};

export default steps;