export const nextStep = () => {
    return {
        type : 'NEXTSTEP'
    }
};

export const prevStep = () => {
    return {
        type : 'PREVSTEP'
    }
};

export const nextSubStep = (next) => {
    return {
        type : 'NEXTSUBSTEP',
        payload : {
            next : next,
        }
    }
};

export const prevSubStep = (prev) => {
    return {
        type : 'PREVSUBSTEP',
        payload : {
            prev : prev
        }
    }
};

export const setSoundscapeID = (step, id) => {
    return {
        type : 'ADDSOUNDSCAPEID',
        payload : {
            step : step,
            id : id
        }
    }
};

export const setLoading = () => {
    return {
        type : 'LOADING'
    }
};

export const setLoaded = () => {
    return {
        type : 'LOADED'
    }
};


