import axios from "axios";

export const pushVerbal = (ID, verbal, step, key) => {
    const link = window.host + 'soundscape-description/';
    axios.post(link,
        {soundscape_id: ID, answer: parseVerbal(verbal)},
            {headers : {Authorization : key}})
        .then(res => {
            console.log(res);
        }).catch(err => console.log("verbal descriptor erro"))
};

export const pushDesirability = (ID, desirability, key) => {
    const link = window.host + 'accuracy/';
    axios.post(link, {ref_id : ID, answer : parseDesirability(desirability)},
    {headers : {Authorization : key}})
        .then(res => console.log(res))
        .catch(err => console.log("desirability error"))

};

export const pushGedrag = (ID, gedrag, step, key) => {
    const link = window.host + 'gedrag/';
    axios.post(link, parseGedrag(ID, gedrag, step), {headers : {Authorization : key} })
        .then(res => {
            console.log(res);
        })
};

export const parseGedrag = (id, gedrag, step) => {
     let data = {ref_id : id, type : step===4?1:2};
    Object.keys(gedrag).map(x=> (data["qu" + x] = gedrag[x]));
    return data;
};

export const parseDesirability = (desirability) => {
    return Object.keys(desirability).map(key => (
        {
            sound_name : key,
            accuracy : desirability[key]
        }
    ))
};

export const parseVerbal = (verbal) => {
    return Object.keys(verbal).map(key => (
        {
            descriptor_id : parseInt(key),
            value : verbal[key] + 1,
        }
    ))
};

export const pushRealityCheck = (ID, choice, key) => {
    axios.post(window.host + 'soundscape-accuracy/',
        {soundscape_id: ID, accuracy: parseRealityChoice(choice)},
        {headers : {Authorization : key}})
        .then(res => {
            console.log(res);
        }).catch(err => console.log("reality check push failed"))

};
export const parseRealityChoice = (choice) => {
    switch (choice) {
        case 'Zeer inaccuraat' :
            return 1;
        case 'Inaccuraat' :
            return 2;
        case 'Redelijk inaccuraat' :
            return 3;
        case  'Neutraal' :
            return 4;
        case  'Redelijk accuraat' :
            return 5;
        case 'Accuraat' :
            return 6;
        case 'Zeer accuraat' :
            return 7;
        default :
            return -1;
    }
};