import React from 'react'
import {Col, Container, Jumbotron, Row} from "react-bootstrap";
import fields from './resource/information_fields'
import TextComponent from "../common_components/TextComponent";
import BasicInformation from "./BasicInformation";
import Vragen from "./Vragen";
import ButtonPanel from "../common_components/ButtonPanel";
import Contact from "./Contact"
import ImageContainer from "../common_components/ImageContainer";
import EndImage from "./resource/img/end.png"
import VolgendeImage from "./resource/img/volgende_onderzoek.png"
import {pushInformation} from "./server_connection/server_requests";
import {useSelector, useDispatch} from "react-redux";
import {setInfo} from "./redux/actions";

/**
 * Serves pages of the information step
 * @param props
 */
function InformationManager(props) {
    const id = useSelector(store => store.refID);
    const info = useSelector(store => store.info);
    const vragen = useSelector(store => store.openAnswers).vragen;
    const ques = useSelector(store => store.sensitivity.questions);
    const dispatch = useDispatch();
    const key = useSelector(store => store.key);

    if(props.step !== 7 || props.substep === 'questions') {
        return null
    }

    const checkBasicInfo = () => {
        if( info.postcode === '' || info.edu_background === '' || info.income === '' ||
            info.ethnicity === '' || info.relationship === '' || info.house_time === '') return true;
        else return false;
    };

    const isInvalid = () => {
        if(props.substep === 'information') {
            return checkBasicInfo()
        } else if(props.substep === 'contact') {
            return !(info[4] || (info['fullname'] !== '' && info['email'] !== '' && info['phone']))
        }
        else if(props.substep === 'end') {
            return true;
        }
        return false;
    };

    const checkContact = () => {
        if(info[4]) {
            dispatch(setInfo('', 'phone', props.substep));
            dispatch(setInfo('', 'fullname', props.substep));
            dispatch(setInfo('', 'email', props.substep))
        }
    };

    const pushData = () => {
        if(props.substep === 'end') {
            pushInformation(id, info, vragen, ques, key)
        }
    };

    return (
        <Jumbotron className = {props.substep === 'volgende_onderzoek' ? "adjustButton" : null}>
            <Container fluid>
                <Row>
                    <Col xs={12} xl = {5} md="auto">
                        <TextComponent hideText = {false} substep = {props.substep}/>
                    </Col>
                    <Col xs={12} xl={7}
                    xs = {{order : props.substep === 'volgende_onderzoek' || props.substep === 'end' ? 'first' : 'last'}}
                    xl = {{order : 'last'}}>
                        {props.substep === 'information' ? <BasicInformation fields = {fields} substep = {props.substep}/> : null}
                        {props.substep === 'vragen' ? <Vragen/> : null}
                        {props.substep === 'contact' ? <Contact/> : null}
                        {props.substep === 'volgende_onderzoek' ? <ImageContainer image={VolgendeImage}/> : null}
                        {props.substep === 'end' ? <ImageContainer image={EndImage}/> : null}
                    </Col>
                </Row>
                <ButtonPanel prev = {() => props.prev()} next = {() => {
                    if(props.substep === 'end') {pushData()} else if(props.substep === 'contact') {checkContact(); props.next()}
                    else props.next()}}
                             isInvalid = {() => isInvalid()}/>
            </Container>
        </Jumbotron>
    )
}

export default InformationManager;