/**
 * Questions for opinions about the research
 */
const ques = [
    {
        id : '1',
        text : "Ik verwacht dat dit onderzoek onafhankelijk zal worden uitgevoerd, zonder enige partijdigheid en externe invloed."
    },
    {
        id : '2',
        text : "Ik verwacht dat dit onderzoek zal worden uitgevoerd volgens de best mogelijke academische standaarden."
    },
    {
        id : '3',
        text : "Ik verwacht dat de onderzoekers transparant zijn en dat de resultaten van dit onderzoek met mij worden gedeeld."
    },
    {
        id : '4',
        text : "Ik verwacht dat er in de toekomst meer lawaai om mij heen zal zijn dan nu."
    },
    {
        id : '5',
        text : "Als ik veel minder geluid hoor in mijn huis, verwacht ik dat mijn slaapkwaliteit zal verbeteren."
    },
    {
        id : '6',
        text : "Als ik veel minder geluid hoor in mijn huis, verwacht ik dat ik productiever zal zijn als ik vanuit huis werk."
    },
    {
        id : '7',
        text : 'Ik denk niet dat dit onderzoek mijn leefomgeving kan verbeteren.'
    },
    {
        id : '8',
        text : 'Ik verwacht dat dit onderzoek kan leiden tot een oplossing die mijn leefomgeving voor mij verbetert.'
    },
    {
        id : '9',
        text : 'Als dit onderzoek tot een oplossing leidt, verwacht ik dit in mijn huis te implementeren.'
    }
];

export default ques;