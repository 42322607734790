import React from 'react';
import './App.css';
import ComponentManager from './components/ComponentManager'

function App() {

    return (
        <div className="App">
            <ComponentManager/>
        </div>
    );
}

export default App;
