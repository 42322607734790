import React from 'react'
import {Container, Row, Col, Image} from "react-bootstrap";
import {useSelector, useDispatch} from "react-redux";
import {addGedrag} from "./redux/actions";

function Gedrag(props) {
    const step = useSelector(store => store.step);
    const selected = useSelector(store => store.gedrag)[step][props.id];
    const dispatch = useDispatch();

    const changeSelection = (x) => {
        if (x !== selected ) {
            dispatch(addGedrag(step, props.id, x))
        }
    };

    const radioStyle = {
        width : '20px',
        height : '20px',
        border : 'none',
        backgroundColor : ' #A3B1C6',
        display : 'table-cell',
        verticalAlign : 'middle'
    };

    const textStyle = {
        textAlign : 'left',
    };

    const getOption = (x) => {
        return <Row>
            <Col xs={2} lg={1} >  <input style = {radioStyle}
                       type="radio"
                       value= {x.id}
                       checked={x.id===selected}
                       onChange = {() => changeSelection(x.id)}
                /> </Col>
            <Col xs={10} lg={10}>
                <p  className = "optionText" style = {textStyle} > {x.text} </p>
            </Col>
        </Row>
    };

    return (
        <Container className = "ml-lg-5">
            <Row className = "mt-lg-4 mb-lg-4" style = {{display : 'block', textAlign : 'left'}}>
                <Image className = "gedrag" fluid src = {props.gedrag.image}  alt = {props.gedrag.alt}/>
            </Row>
            <Row className = "mt-2 mr-xl-5" xs = {1} xl={2}>
                {props.gedrag.options.map(x=> <Col className = 'mt-3'> {getOption(x)}</Col>)}
            </Row>
        </Container>
    )
}

export default Gedrag;