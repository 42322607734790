import ques from '../../data_constant/mood_questions'

const initial = {};

ques.map(x=>{
    if(x.type === 'slider') initial[x.id] = 3;
    else if(x.type === 'options' && x.multiple) initial[x.id] = [];
    else initial[x.id] = ''
});

export const noiseQues = (state = initial, action) => {
    switch (action.type) {
        case 'ADDNOISEQUES' : {
            return {
                ...state,
                [action.payload.situation]: action.payload.answer
            };
        }
        case 'ADDNOISEQUESMULTIPLE' : {
            return {
                ...state,
                [action.payload.situation] : [action.payload.answer, ...state[action.payload.situation]]
            }
        }
        case 'REMOVEMULTIPLE' : {
            return {
                ...state,
                [action.payload.situation] : state[action.payload.situation].filter(x=> x!==action.payload.answer)
            }
        }
        default :
            return state;
    }
};