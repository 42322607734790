import axios from "axios";

export const sendProfile = (ID, age, gender, mood, key) => {
    axios.post( window.host + 'profile/',
        {ref_id : ID, age : parseAge(age), gender : parseGender(gender), pick_a_mood : parseInt(mood)},
        {headers : {
                Authorization : key
            }})
        .then(res => {
            console.log(res);
        }).catch(err => {
        if (err.response) {
            console.log('server responded')
        } else if (err.request) {
            console.log('client never received a response, or request never left')
        } else {
           console.log(err)
        }
    })
};


export const parseGender = (gender) => {
    switch(gender) {
        case 'Man' : return 'M';
        case 'Vrouw' : return 'F';
        default : return 'U'
    }
};

export const parseAge = (age) => {
    switch (age) {
        case 'Onder 18 jaar' : return 1;
        case '18-24 jaar' : return 2;
        case '25-34 jaar' : return 3;
        case '35-44 jaar' : return 4;
        case '45-54 jaar' : return 5;
        case '55-64 jaar' : return 6;
        case '65-74 jaar' : return 7;
        case '75 jaar of ouder' : return 8;
        default : return -1;
    }
};
