export const changeDesirability = (sound, rating) => {
    return {
        type: 'CHANGEDESIRABILITY',
        payload: {
            sound: sound,
            rating: rating
        }
    }
};
export const addGedrag = (step, situation, choice) => {
    return {
        type: 'ADDGEDRAG',
        payload: {
            gedragStep : step,
            situation: situation,
            choice: choice
        }
    }
};
export const addVerbal = (step, word, rating) => {
    return {
        type: 'ADDVERBAL',
        payload: {
            verbalStep : step,
            word: word,
            rating: rating
        }
    }
};
export const changeWordCount = (count) => {
    return {
        type: 'CHANGEWORDCOUNT',
        payload: {
            count: count
        }
    }
};

export const changeSituationCount = (count) => {
    return {
        type : 'CHANGESITUATIONCOUNT',
        payload : {
            count : count
        }
    }
};

export const activate = () => {
    return {
        type : 'ACTIVATE'
    }
};

export const changeDesirabilityCount = (count) => {
    return {
        type : 'SETDESIRABILITYQUES',
        payload : count
    }
};

export const changeAccuracy = (data) => {
    return {
        type: 'CHANGEACCURACY',
        payload: {
            data: data
        }
    }
};