import React from 'react'
import {Col, Container, Image, Jumbotron, Row} from "react-bootstrap";
import TextComponent from "../common_components/TextComponent";
import WelcomeImage from "./resource/img/welcome_screen_image.png";


function WelcomeIntro(props) {
    if(props.substep !== 'survey_intro') {
        return null;
    }

    return (
        <Jumbotron>
            <Container fluid>
                <Row>
                    <Col xs={{order : 'last'}} xl={{order : 'first'}}>
                        <Row>
                            <TextComponent hideText = {false} substep = {props.substep}/>
                        </Row>
                        <Row>
                            <button id='start' onClick={() =>{props.scroll(); props.next()}} className="mt-4 mx-md-0 mx-sm-auto mx-xs-auto">Start het onderzoek</button>
                        </Row>
                    </Col>
                    <Col xs={12} xl={7}>
                        <Image src= {WelcomeImage} fluid rounded/>
                    </Col>

                </Row>
            </Container>
        </Jumbotron>

    )
}

export default WelcomeIntro;