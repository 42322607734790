import React, {useState} from 'react'
import {Container, Row} from 'react-bootstrap'
import ButtonPanel from "../common_components/ButtonPanel";
import {useSelector, useDispatch} from "react-redux";
import nature_noise from "../data_constant/nature_noise_icon";
import Desirability from "./Desirability";
import {changeDesirabilityCount} from './redux/actions'
import {pushDesirability} from "./server_connection/server_request";

function DesirabilityManager(props) {
    const count = useSelector(store => store.desirabilityCount);
    const dispatch = useDispatch();
    const id = useSelector(store => store.refID);
    const outdoor_sounds = useSelector(store => store.selectedSounds.initialSounds);
    const selected = Object.keys(outdoor_sounds).concat(nature_noise);
    const desirability = useSelector(store => store.desirability);
    const currentDesirability = desirability[selected[count]];
     const key = useSelector(store => store.key);

    const next = () => {
        if(count < selected.length -1 ) {
            dispatch(changeDesirabilityCount(count + 1))
        }
        else {
            pushDesirability(id, desirability, key);
            props.next()
        }
    };

    const prev = () => {
        if(count > 0) {
            dispatch(changeDesirabilityCount(count - 1))
        } else {props.prev()}
    };


    const isInvalid = () => {
        return currentDesirability == null
    };

    return (
        <Container fluid>
            <Row>
                <Desirability sound = {selected[count]}/>
            </Row>
            <ButtonPanel next = {next} prev = {prev} isInvalid = {() => isInvalid()}/>
        </Container>
    )
}

export default DesirabilityManager