import React, {useState} from 'react'
import {Row} from "react-bootstrap";
import {useSelector} from "react-redux";
import FinishPopup from './FinishPopup'

/**
 * Panel containing vorige and volgende buttons
 */
function ButtonPanel(props) {

    const substep = useSelector(store => store.substep);
    const [finish, setFinish] = useState(false);

    /**
     * On clicking a button, next page should be displayed from the top
     */
    const scrollToTop = () => {
        window.scrollTo({
            top: 0
        });
    };

    const disabledStyleCheck = {

        opacity :  props.isInvalid() ? '30%' : '100%'
    };


    return (
        <Row className = {finish ? "d-none" :'fixedButtons'} >
            <button
                onClick = {() => {scrollToTop(); props.prev()}}
                className = {substep ==='new_soundscape' || substep === 'optimized_soundscape' ? "d-none" : "mx-4 mx-md-2 my-4"}
                >
                Vorige
            </button>
            {substep !== 'end' ?
            <button
                style = {disabledStyleCheck}
                disabled={ props.isInvalid() }
                onClick = {() => {scrollToTop(); props.next()}}
                className = {substep ==='new_soundscape' || substep === 'optimized_soundscape' ? "mx-4 mx-md-2 my-4": "mx-md-5 my-4"}

                >
                Volgende
            </button> :
            <button
                className = "mx-md-5 my-4"
                onClick = {() => {setFinish(true); props.next()}}
                >
                Versturen
            </button>}
            {finish ? <FinishPopup/> : null}
        </Row>
    )
}

export default ButtonPanel;