import React from 'react'
import ReactHowler from "react-howler";
import audioAssets from '../components/data_constant/noise_icon'
import humAudio from '../components/soundscape_step/resource/audio/Hum.wav'
import nature from '../components/data_constant/nature_noise_icon'
import {useSelector} from "react-redux";

function Howler(props) {

    const selectedSounds = useSelector(store => store.selectedSounds);
    const random = useSelector(store => store.random);
    const init = selectedSounds.initialSounds;
    const indoor = selectedSounds.indoorSounds;
    const desirability = useSelector(store => store.desirability);
    const hum = useSelector(store=>store.hum);

    const getHowler = (name, dir, volume) => {
        return <ReactHowler
            src = {audioAssets[name].audio}
            loop = {true}
            playing = {props.substep !== 'desirability_check' && props.substep !== 'new_soundscape'}
            volume = {volume/100}
        />
    };

    const getHowlerFromAws = (name, volume)=> {
        return <ReactHowler
            src = {audioAssets[name].audio_denoize}
            loop = {true}
            playing = {props.substep !== 'desirability_check' && props.substep !== 'new_soundscape'}
            volume = {volume/100}
        />
    };

    const getDesirabilitySound = (des) => {
        const volumes = Object.keys(init).map(x=> init[x]);
        const maxVolume = Math.max(...volumes);

        const desRating = nature.map(x=>des[x]);
        const maxRating = Math.max(...desRating);
        const maxRatedSounds = nature.filter(x=> des[x] === maxRating );
        //console.log(maxRatedSounds);
        const pick = maxRatedSounds[Math.floor(random * maxRatedSounds.length)];
        //console.log(pick);
        //const pick = maxRatedSounds[0];

        return  <ReactHowler
            src = {audioAssets[pick].audio}
            loop = {true}
            playing = {props.substep !== 'desirability_check' && props.substep !== 'new_soundscape'}
            volume = {maxVolume/100}
        />
    };


    return (

        <div>
            <ReactHowler
                src = {humAudio}
                loop = {true}
                playing = {props.substep !== 'desirability_check' && props.substep !== 'new_soundscape'}
                volume = {hum/100}
            />

            {props.step === 4 ? Object.keys(init).map(x=> getHowler(x, 'outdoor',  init[x])) : Object.keys(init).map(x=> getHowlerFromAws(x, init[x]))}
            {props.step === 4 ? Object.keys(indoor).map(x=>getHowler(x, 'indoor', indoor[x])) : Object.keys(indoor).map(x=> getHowlerFromAws(x, indoor[x]))}
            {props.step === 6 ? getDesirabilitySound(desirability) : null}
        </div>

    )
}

export default Howler