import React, {useState} from 'react'
import Slider from "react-input-slider";
import {useDispatch} from "react-redux";
import ReactHowler from 'react-howler'
import {changeVolume} from "./redux/actions";

function VolumeSlider(props) {
    const dispatch = useDispatch();
    const [play, setPlay] = useState(false);

    const SliderStyle = {
        background: '#ECF0F3 0% 0% no-repeat padding-box',
        borderRadius: '51px',
        width: '100%',
        height: '45px',
        outline: 'none',
        //opacity: '0.7',
        WebkitTransition: '.2s',
        transition: 'opacity .2s',
        border: 'none',
        boxShadow: '6px 6px 8px rgba(163,177,198, 0.6) inset, -6px -6px 8px    rgba(255,255,255, 0.5) inset'
    };
    const bg = props.image;
    const volume = props.selected[props.sound];

    return (
         <div style = {{width : '90%', marginRight : 'auto', marginLeft: 'auto'}} onMouseOver={() => {setPlay(true)}} onMouseLeave={() => setPlay(false)}
        onTouchEnd={() => setPlay(false)} onTouchMove = {() => setPlay(true)}>
            <Slider
                x={volume}
                onChange={({ x }) => { dispatch(changeVolume(props.sound, x, props.soundType))}}
                styles = {{
                    track : SliderStyle,
                    active : SliderStyle,
                    thumb : {
                        width: '60px', height: '60px', borderRadius: '50%',
                       backgroundImage: `url(${bg})`, backgroundColor : '#EBEEF1',
                        backgroundRepeat: 'no-repeat', backgroundPosition: 'center',
                        backgroundOrigin : 'padding-box', backgroundSize : '30px auto, contain',
                       // , , ,

                        boxShadow: "9px 9px 16px rgba(163,177,198,0.6), -9px -9px 16px rgba(255,255,255, 0.5)",
                        cursor: 'pointer'
                    }
                }}
            />
            <ReactHowler
                src = {props.audio}
                playing = {play}
                volume = {volume/100}

            />
        </div>
    )
}

export default VolumeSlider;