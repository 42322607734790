import React from 'react'
import assets from "../data_constant/gedrag";
import Gedrag from "./Gedrag";
import {useSelector} from "react-redux";
import {Col, Container, Row} from "react-bootstrap";
import ButtonPanel from "../common_components/ButtonPanel";
import TextComponent from "../common_components/TextComponent";
import {changeSituationCount, changeDesirabilityCount} from "./redux/actions";
import {useDispatch} from "react-redux";
import {pushGedrag} from "./server_connection/server_request";

function GedragManager(props) {
    const situationCount = useSelector(store => store.situation);
    const situations = Object.keys(assets);
    const currentGedrag = useSelector(store => store.gedrag);
    const dispatch = useDispatch();
    const id = useSelector(store => store.refID);
     const key = useSelector(store => store.key);

    const next = () => {
        if(situationCount < situations.length -1) {
            dispatch(changeSituationCount(situationCount + 1))
        } else
        {
            pushGedrag(id, currentGedrag[props.step], props.step, key);
            props.next()
        }
    };

    const prev = () => {
        if(situationCount > 0) {
            dispatch(changeSituationCount(situationCount - 1))
        } else {
            props.prev()
        }
    };

    const isInvalid = () => {
        return currentGedrag[props.step][situationCount+1] == null
    };

    const hideText = () => {
        return situationCount !== 0
    };

    return (
        <Container fluid className = "adjustButton">
            <Row >
                <Col xs={12} xl={5}>
                    <TextComponent
                        hideText={hideText()}
                        substep={props.substep}/>
                        <p style = {{fontWeight : 'bold'}}> Dagelijkse situatie: </p>
                        <p> {assets[situations[situationCount]].situation}</p>
                </Col>
                <Col xs={12} xl={7}>
                    <Gedrag id = {situations[situationCount]} gedrag = {assets[situations[situationCount]]}/>
                </Col>
            </Row>
            <ButtonPanel next = {next} prev = {prev} isInvalid = {() => isInvalid()}/>
        </Container>
    )
}

export default GedragManager