const questions = [
    {
        id : '1',
        text : "Ik heb veel last van buitengeluid."
    },
    {
        id : '2',
        text : "Ik zou graag minder lawaai en meer comfort in huis willen."
    },
    {
        id : '3',
        text : "Ik zou graag minder lawaai en meer comfort buitenshuis willen."
    },
    {
        id : '4',
        text : "Ik heb veel oplossingen geprobeerd om het geluid in mijn huis te verminderen (bijv.: het vervangen van de ramen, het dak, enz.)."
    },
    {
        id : '5',
        text : "Ik ben tevreden met de oplossingen die ik heb ingezet  om het geluid in mijn huis te verminderen."
    },
    {
        id : '6',
        text : "Ik zou een betere optie uitproberen om mijn huis comfortabeler te maken."
    },
    {
        id : '7',
        text : "Hoeveel zou je bereid zijn te betalen om minder lawaai en meer comfort in jouw huis te hebben?",
        placeholder : 'Bedrag per jaar',
         options : [{id : 1, text :'minder dan \u20ac1.000'},
             {id : 2, text :'\u20ac1.000 - \u20ac1.500'},
             {id : 3, text :'\u20ac1.500 - \u20ac2.000'},
             {id : 4, text : '\u20ac2.000 - \u20ac3.000'},
             {id : 5, text :'\u20ac3.000 - \u20ac4.000'},
             {id : 6, text :'\u20ac4.000 - \u20ac5.000'},
             {id : 7, text :'If someone can eliminate the noise for me, I don\'t care how much it costs'}]
    }
];

export default questions;