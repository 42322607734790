import React, {useState} from 'react'
import {Form, Container, Row} from "react-bootstrap";
import {useDispatch, useSelector} from 'react-redux'
import {setAge, setGender, setSensitivityQuestion} from "./redux/actions";

/**
 * Form where user can fill in their age and gender
 */
function AgeGenderForm(props) {

    const dispatch = useDispatch();
    const data = useSelector(store => store.welcome_data);

    const formStyle = {
        height: "3.7em",
        float : "center",
        border: "none",
        textAlign : 'center',
        backgroundColor : "#EBEEF1",
        boxShadow: "9px 9px 16px rgba(211, 214, 216, 0.6) inset, -9px -9px 16px    rgba(255,255,255, 0.5) inset",
        textAlignLast : 'center'
    };

    const handle_change = event => {
        let form = event.target;
        if (form.id === 'age') {
            dispatch(setAge(form.value))
        }
        if(form.id === 'gender') {
            dispatch(setGender(form.value))
        }
    };

    const isInvalid = () => {
        if (data.age === '' || data.gender === ''){
            return true;
        } else return false
    };


    return (
        <Container fluid>
            <Row>
                <Form className = "mx-auto" style = {{width : '100%'}}>

                    <Form.Group className="my-4 mx-auto " controlId="gender" name = "gender" onChange={handle_change}>
                        <Form.Control className="my-3 mx-auto rounded-pill userdata" style={formStyle} as="select" >
                            <option> {data.gender === '' ? 'Geslacht' : data.gender} </option>
                            <option>Man</option>
                            <option>Vrouw</option>
                            <option>Vertel ik liever niet</option>
                        </Form.Control>
                    </Form.Group>

                    <Form.Group className="my-4 mx-auto " controlId="age" name = "age" onChange={handle_change}>
                        <Form.Control className="my-3 mx-auto rounded-pill userdata" style={formStyle} as="select" >
                            <option> {data.age === '' ? 'Leeftijds groep' : data.age} </option>
                            <option>Onder 18 jaar</option>
                            <option>18-24 jaar</option>
                            <option>25-34 jaar</option>
                            <option>35-44 jaar</option>
                            <option>45-54 jaar</option>
                            <option>55-64 jaar</option>
                            <option>65-74 jaar</option>
                            <option>75 jaar of ouder</option>
                        </Form.Control>
                    </Form.Group>

                </Form>
            </Row>
            <Row>
                <button
                    style = {{opacity : isInvalid() ? '30%' : '100%'}}
                    disabled={isInvalid()}
                    onClick = {() =>
                    {dispatch(setSensitivityQuestion(0, 'sound_sensitivity'));
                    props.next()}}
                    className = "mt-4 mx-auto oval" >Volgende
                </button>
            </Row>
        </Container>
    )
}

export default AgeGenderForm;

