/**
 * List of extra noise icons for new category
 * To be used as keys for noise icon dictionary
 */
const noise_icon = [
    'Airco', 'Wasmachine', 'Fornuis', 'Computerventilator', 'TL buis ruis',
    'Afzuigkap', 'Douche', 'Vriezer', 'Klok', //'TV'
];

export default noise_icon;
