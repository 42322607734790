import React from 'react'
import {useDispatch, useSelector} from "react-redux";
import './slider.css'
import {addNoiseQues} from "../mood_step/redux/actions";
import {addVerbal} from "../user_response_step/redux/actions";

function CustomSlider(props) {
    const step = useSelector(store => store.step);
    const verbalValue = useSelector(store => store.verbal);
    const quesValue = useSelector(store => store.noiseQues);
    let value = props.substep === 'verbal_test' ? verbalValue[step][props.label] : quesValue[props.label];
    
    const dispatch = useDispatch();
    if(value == undefined) {
        dispatch(addNoiseQues(props.label, 3))
    }


    const handleChange = (x) => {
        if(props.substep === 'verbal_test') {
            dispatch(addVerbal(step, props.label, x));
        } else if(props.substep === 'basic_noise_ques') {
            dispatch(addNoiseQues(props.label, x))
        }
    };

    return (

            <div className="range" style = {{width : props.substep === 'verbal_test' ? '80%' : '100%', marginRight : 'auto', marginLeft : 'auto'}}>
                {props.displayTag ?
                    <div style={{display: 'flex', justifyContent: 'space-between'}}>
                        <span className="sliderText" style = {{justifyContent : 'left', width : '20%', textAlign : 'left'}}>{props.options[0]}</span>
                        <span className = "sliderText" style = {{justifyContent : 'right', width : '20%', textAlign : 'right'}}>{props.options[1]}</span>
                    </div> : null
                }
                <div className="ticks">
                    <span className="tickEdge"/>
                    <span className="tick"/>
                    <span className="tick"/>
                    <span className="tick"/>
                    <span className="tick"/>
                    <span className="tick"/>
                    <span className="tickEdge"/>
                </div>
                <input value = {value}
                       type="range" min="0" max="6" className = 'slider'
                        onChange = {(x) => handleChange(x.target.value)
                        }/>
            </div>

    )
}

export default CustomSlider;