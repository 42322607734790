export const welcome_data = (state = {age: '', gender: ''}, action) => {
    switch (action.type) {
        case 'AGE' :
            return {...state, age: action.payload};
        case 'GENDER' :
            return {...state, gender: action.payload};
        default:
            return state
    }
};
export const mood = (state = '', action) => {
    switch (action.type) {
        case 'SETMOOD' :
            return action.payload;
        default :
            return state;
    }
};