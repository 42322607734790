import React from 'react'
import {Col, Container, Image, Jumbotron, Row} from "react-bootstrap";
import WelcomeDataImage from "./resource/img/welcome_screen_image.png";
import AgeGenderForm from "./AgeGenderForm";

function WelcomeData (props) {

    if (props.substep !== 'welcome_data') {
        return null;
    }

    return (
        <Jumbotron>
            <Container fluid>
                <Row xs={1} md={1} lg = {1} xl = {2}>
                    <Col>
                        <Image style ={{width:"100%", height: "100%"}} src= {WelcomeDataImage} fluid rounded />
                    </Col>
                    <Col className= "p-5">
                        <Row>
                            <h2  className= "mx-auto">Algemene informatie</h2>
                        </Row>
                        <Row className="mt-5 show-grid" float="center">
                            <AgeGenderForm next = {props.next}/>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </Jumbotron>
    )
}

export default WelcomeData