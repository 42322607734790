const information = {
    postcode : '',
    edu_background : '',
    income : '',
    ethnicity : '',
    relationship : '',
    house_time : '',
    fullname: '',
    email : '',
    phone : ''
};

export const info = (state = information, action) => {
    switch(action.type) {
        case 'ADDINFORMATION' : {
            return {
                ...state,
                [action.payload.field]: action.payload.answer
            };
        }
        default :
            return state;
    }
};


export const openAnswers = (state = {extra_answer : '', vragen : ''}, action) => {
    switch(action.type) {
        case 'ADDOPENANSWER' : {
            return {
                ...state,
                [action.payload.substep] : action.payload.answer
            }
        }
        default :
            return state;

    }
};