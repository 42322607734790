import React, {useState} from 'react';
import {Modal, ModalBody, Button} from 'react-bootstrap'

function FinishPopup(props)  {
         const [show, setShow] = useState(true);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title>Voltooid</Modal.Title>
        </Modal.Header>
        <Modal.Body>Het onderzoek is succesvol verstuurd, bedankt voor je bijdrage!</Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleClose}>
            Sluiten
          </Button>
        </Modal.Footer>
      </Modal>
    </>

)
}

export default FinishPopup