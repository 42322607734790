import React, {useState} from 'react'
import {Container, Row, Col, Form} from "react-bootstrap";
import CustomSlider from "./CustomSlider";
import {useDispatch, useSelector} from "react-redux";
import {addNoiseQues, addNoiseQuesMultiple, removeNoiseQuesMultiple} from "../mood_step/redux/actions";

/**
 * Questions (right panel) for sound sensitivity test.
 * @param props contains :
 * id : id of the questions being asked
 * text : text of the question being asked
 * type : ['options'| 'slider]
 * options : if type = options, the option text
 * substep : current substep
 */
function Question(props) {
    const ques = useSelector(store => store.noiseQues);
    const dispatch = useDispatch();
    const [neutral, toggle] = useState(true);

    const selected = ques[props.question.id];

    const changeSelection = (x) => {
        if(props.question.type === 'options' && props.question.multiple) {
            if(!selected.includes(x)) {
                dispatch(addNoiseQuesMultiple(props.question.id, x))
            } else dispatch (removeNoiseQuesMultiple(props.question.id, x))
        }
        else if(x!== selected) {
            dispatch(addNoiseQues(props.question.id, x))
        }
    };

    const radioStyle = {
        width : '25px',
        height : '25px',
        border : 'none',
        backgroundColor : ' #A3B1C6',
    };

    const labelStyle = {
        width : '100%',
        textAlign: 'center',
        color: '#393E46',
        opacity: '1',
        paddingLeft : '10px'
    };

    const textRowStyle = {
        width : '100%',
        height : '50%',
        display : 'table',
        marginRight : 'auto',
        marginLeft : 'auto',
        textAlign : 'center'
    };

    const questionStyle = {
        width : '100%',
        fontWeight : 'bold',
        display : 'table-cell',
        verticalAlign : 'middle',
        textAlign : 'center'
    };

    const getOption = (x, number) => {
        if(number <=2) {
            return <div className="radio" style={{textAlign: 'center'}}>
                {getInput(x.id, '0px')}
                <label style={labelStyle}>{x.text}
                </label>
            </div>
        } else return (
            <Row style = {{height : '100%'}}>
                <Col xs={1} md={1} style = {{height : '100%'}}>
                    {getInput(x.id, '20px')}
                </Col>
                <Col xs = {10} md={10} style = {{height : '100%'}}>
                    <label  className="my-auto" style = {{...labelStyle, textAlign : 'left'}}> {x.text}</label>
                </Col>
            </Row>
        )
    };

    const getInput = (x, marginRight) => {
        return <input style = {{...radioStyle, marginRight : marginRight}}
                      type={props.question.multiple ? "checkbox" : "radio"}
                      value= {x}
                      checked={props.question.multiple ? !(selected == undefined) && selected.includes(x) : x===selected}
                      onClick = {() => changeSelection(x)}
        />
    };

    const handleNeutral = (id) => {
        toggle(!neutral)
        if(selected === -1) {
            dispatch(addNoiseQues(id, 3))
        } else dispatch(addNoiseQues(id, -1))
    };

    return (
        <Container fluid className = "my-lg-5 containerStyle widthControl heightControl" >
            <Row style = {textRowStyle} className = "mb-5 mb-md-0 px-lg-5">
                <p style ={questionStyle}>{props.question.text}</p>
            </Row>

            <Row
                className = 'rowCenter'
                style = {{ height : props.question.type === 'options' ? '50%' : '0%'}}
                 xs={props.question.options.length > 2 ? 1 : 2} md={2}>
                {props.question.type === 'options' ?
                        props.question.options.map(x=> <Col className = "my-auto"> {getOption(x, props.question.options.length)} </Col>)
                    : null}
            </Row>

            <Row style = {{...textRowStyle, height : props.question.type === 'slider' ? '50%' : '0%'}} className = "px-lg-5">
                {props.question.type === 'slider' ?
                    <span  style = {{width : '100%', display : 'table-cell', verticalAlign : 'middle'}}>
                        <div className = {selected === -1 ? 'd-none' : null}>
                    <CustomSlider
                        substep = {props.substep}
                        label = {props.question.id}
                        displayTag = {true}
                        options = {props.question.options}
                    /></div>
                        {props.question.neutral !== undefined ?
                            <p className = "mt-5 sliderText"
                               onClick = {() => handleNeutral(props.question.id)}
                               style = {{textAlign :'center', width : '100%', cursor : 'pointer', color :  neutral ? '#393E46' :'#0558FC'}}>
                                {props.question.neutral}
                            </p> : null}
                    </span> : null}

            </Row>

            <Row style = {{height : props.question.type === 'textbox' ? '50%' : '0%'}} >
                {props.question.type === 'textbox' ?
                    <Form.Group controlId="feedback" style = {{width : '100%', textAlign : "center"}} >
                        <Form.Control  className = "containerStyle"  as="textarea" value = {selected} rows = "5"
                                       onChange = {(event) => changeSelection(event.target.value)} />
                    </Form.Group>
                    :
                    null}
            </Row>

        </Container>
    )
}

export default Question;