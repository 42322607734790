import React, {useEffect, useState} from 'react'
import assets from '../data_constant/noise_icon'
import {Container, Row, Col} from 'react-bootstrap'
import {useDispatch, useSelector} from "react-redux";
import './desirabilityStyle.css'
import {changeDesirability} from "./redux/actions";
import ReactHowler from "react-howler";
import options from './resource/desirability_options'

function Desirability(props) {
    const dispatch = useDispatch();
    const [max, setMax] = useState(0);
    const rating = useSelector(store => store.desirability)[props.sound];
    const outdoor_sounds = useSelector(store => store.selectedSounds.initialSounds);


    useEffect(() => {
        const volumes = Object.keys(outdoor_sounds).map(x=> outdoor_sounds[x]);
        const maximum = Math.max(...volumes);
        setMax(maximum);
    }, [outdoor_sounds]);

    const getOption = (x) => {
        return <div className="d-none d-xl-block radioContainer my-2" >
            <input
                className = 'radioOption'
                type="radio"
                value= {x.id}
                checked={x.id===rating}
                onChange = {() => dispatch(changeDesirability(props.sound, x.id))}
            />
            <p className = "optionText">{x.text}
            </p>
        </div>
    };

    const getAdjOption = (x) => {
        return <Col className="d-block d-xl-none radioContainer my-2" xs={10}>
            <p className = "optionText">
                <input
                    className = 'radioOption'
                    type="radio"
                    value= {x.id}
                    checked={x.id===rating}
                    onChange = {() => dispatch(changeDesirability(props.sound, x.id))}
                />
                {x.text}
            </p>
        </Col>
    };

    return (
        <Container fluid>
            <Row className = "mx-md-5 desirabilityPanel autoMargin">
                <img className = "p-md-5 desirabilityImg" src = {assets[props.sound].highres}/>
            </Row>
            <Row className = "my-4 mx-md-5 autoMargin" style = {{display : 'flex', justifyContent : 'space-between'}}>
                {options.map(x=> getOption(x))}
            </Row>
            <Row className = "mt-4 mx-md-5 " xs={2}>
                {options.map(x=>getAdjOption(x))}
            </Row>
            <ReactHowler
                src = {assets[props.sound].audio}
                playing = {true}
                loop = {true}
               volume = {outdoor_sounds[props.sound] !== undefined ? parseInt(outdoor_sounds[props.sound])/100 : max/100}
            />

        </Container>
    );


}

export default Desirability;