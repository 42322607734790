import React from 'react'
import logo from './resource/denoize_logo.png'
import ProgressBar from './ProgressBar'


function Footer(props) {
    const footerStyle = {
        background: "#A3B1C6",
        padding: "20px",
        position: "fixed",
        left: "0",
        bottom: "0",
        height: "60px",
        width: "100%",
    };


    return (
        <footer style = {footerStyle}>
            <span>
                <img className = 'mx-xs-3 mx-md-5 footerLogo'
                     src = {logo}
                />
            </span>

            <span>
                <ProgressBar percentage = {props.percentage}/>
            </span>
            <span style = {{float : 'right', color : "#E0E5EC"}}>
                <div style = {{marginRight : '20px'}}>
                    {Math.ceil(props.percentage)}%
                </div>
            </span>

        </footer>
    )
}

export default Footer;