import words from "../../data_constant/verbal";

const initial = {};
words.map(word => {if(word[2] !==undefined) {initial[word[2]] = 3}});

export const desirability = (state = {}, action) => {
    switch (action.type) {
        case 'CHANGEDESIRABILITY' :
            return {
                ...state,
                [action.payload.sound]: action.payload.rating
            };
        default :
            return state;
    }
};


export const gedrag = (state = {4 : {}, 6:{}}, action) => {
    switch (action.type) {
        case 'ADDGEDRAG' :
            return {
                ...state,
                [action.payload.gedragStep]: {
                    ...state[action.payload.gedragStep],
                    [action.payload.situation]: action.payload.choice
                }
            };
        default :
            return state;
    }
};

export const verbal = (state = {4: initial, 5: initial, 6:initial}, action) => {
    switch (action.type) {
        case 'ADDVERBAL':
            return {
                ...state,
                [action.payload.verbalStep]: {
                    ...state[action.payload.verbalStep],
                    [action.payload.word]: parseInt(action.payload.rating)
                }
            };
        default :
            return state
    }
};

export const wordCount = (state = 0, action) => {
    switch (action.type) {
        case 'CHANGEWORDCOUNT' : {
            return action.payload.count
        }
        default :
            return state;
    }
};

export const situation = (state = 0, action) => {
    switch (action.type) {
        case 'CHANGESITUATIONCOUNT' : {
            return action.payload.count
        }
        default : return state
    }
};

export const breakInvalid = (state = true, action) => {
    switch (action.type) {
        case 'ACTIVATE' : {return false}
        default : return state
    }
};

export const desirabilityCount = (state = 0, action) => {
    switch (action.type) {
        case 'SETDESIRABILITYQUES' : {
            return action.payload
        }
        default : return state
    }
};
export const accuracy = (state = '', action) => {
    switch (action.type) {
        case 'CHANGEACCURACY' : {
            return action.payload.data;
        }
        default :
            return state;
    }
};