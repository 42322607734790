export const step = (state = 0, action) => {
    switch(action.type) {
        case 'NEXTSTEP':
            return state + 1;
        case 'PREVSTEP':
            return state > 1 ? state-1 : state;
        case 'LOGIN' : return 1;
        default :
            return state
    }
};

export const substep = (state= 'login', action) => {
    switch(action.type) {
        case 'NEXTSUBSTEP' :
            return action.payload.next;
        case 'PREVSUBSTEP' :
            return action.payload.prev;
        case 'LOGIN' : return 'welcome_screen';
        default :
            return state
    }
};

export const refID = (state = '', action) => {
    switch(action.type) {
        case 'SETREFID' :
            return action.payload;
        default :
            return state
    }
};


export const table_question = (state = {sound_sensitivity: 0, opinions: 0, hopes: 0, questions: 0}, action) => {
    switch (action.type) {
        case 'SETQUESTION' : {
            return {
                ...state,
                [action.payload.quesType]: action.payload.number
            };
        }
        default :
            return state;
    }
};
export const sensitivity = (state = {sound_sensitivity: {}, opinions: {}, hopes: {}, questions: {}}, action) => {
    switch (action.type) {
        case 'ADDSENSITIVITY' : {
            return {
                ...state,
                [action.payload.quesType]: {
                    ...state[action.payload.quesType],
                    [action.payload.situation]: action.payload.answer
                }
            };
        }
        default :
            return state;
    }
};

export const soundscapeID = (state = {3 : '', 5 : '', 6 : ''}, action) => {
    switch (action.type) {
        case 'ADDSOUNDSCAPEID' : {
            return {
                ...state,
                [action.payload.step] : action.payload.id
            }
        }
        default : return state;
    }
};

export const loading = (state = true, action) => {
    switch(action.type) {
        case 'LOADED' : {
            return false;
        }
        case 'LOADING' : {
            return true;
        }
        default : return state;
    }
};

const init = Math.random();

export const random = (state = init) => {
    return state
};