/**
 * Situations for behaviour test
 */
const gedrag = {
    1 : {
        image : require('../resource/situation_img/waking_up.png'),
        alt : 'Waking Up',
        situation : "Je wordt wakker in de ochtend.",
        options :
            [
                {
                    id : 1,
                    text : "Ik negeer gewoon het geluid en begin mijn dag."
                },
                {
                    id : 2,
                    text : "Ik bedek mijn oren met een kussen en probeer wat meer te slapen."
                },
                {
                    id : 3,
                    text : "Ik gebruik mijn telefoon om mezelf af te leiden van het lawaai"
                },
                {
                    id : 4,
                    text : "Ik ga naar een stillere kamer in mijn huis."
                }
            ]
    },


    2 : {
        image : require('../resource/situation_img/reading.png'),
        alt : 'Reading',
        situation : 'Lezen in de ochtend (krant, app, etc).',
        options :
            [
                {
                    id : 1,
                    text : " Ik negeer het geluid en lees verder."
                },
                {
                    id : 2,
                    text : "Ik zet de radio aan of zet wat achtergrondmuziek op."
                },
                {
                    id : 3,
                    text : "Ik lees even en lees later verder als het rustiger is. "
                },
                {
                    id : 4,
                    text : "Ik stop met lezen en zet de tv aan of ga iets anders doen."
                }
            ]
    },

    3 : {
        image : require('../resource/situation_img/working_from_home.png'),
        alt : 'Working from home',
        situation : 'Je werkt vanuit huis',
        options :
            [
                {
                    id : 1,
                    text : "Ik negeer gewoon het geluid en maak mijn werk af."
                },
                {
                    id : 2,
                    text : "Ik gebruik een koptelefoon of achtergrondmuziek om me te concentreren."
                },
                {
                    id : 3,
                    text : "Ik neem af en toe een pauze omdat het lawaai vermoeiend is."
                },
                {
                    id : 4,
                    text : "Ik ga naar een stillere kamer in mijn huis en ga verder met werken."
                }
            ]
    },

    4 : {
        image : require('../resource/situation_img/eating.png'),
        alt : 'Eating',
        situation : 'Aan tafel voor het avondeten',
        options :
            [
                {
                    id : 1,
                    text : "Ik negeer het geluid en ga door met eten."
                },
                {
                    id : 2,
                    text : "Ik zet de radio aan of zet wat achtergrondmuziek op."
                },
                {
                    id : 3,
                    text : "Ik begin aan de eettafel een gesprek over het geluid dat we horen."
                },
                {
                    id : 4,
                    text : "De volgende keer ga ik voor de maaltijden naar een rustigere plek in mijn huis."
                }
            ]
    },

    5 : {
        image : require('../resource/situation_img/relaxing.png'),
        alt : 'Relaxing',
        situation : 'Een relaxmomentje aan het einde van de dag',
        options :
            [
                {
                    id : 1,
                    text : "Ik negeer het geluid en ontspan."
                },
                {
                    id : 2,
                    text : "Ik verhoog het volume van mijn tv om de andere geluiden te maskeren."
                },
                {
                    id : 3,
                    text : "Ik gebruik mijn telefoon om mezelf af te leiden."
                },
                {
                    id : 4,
                    text : "Ik verplaats naar een rustigere kamer in mijn huis om te ontspannen."
                }
            ]
    },

    6 : {
        image : require('../resource/situation_img/falling_asleep.png'),
        alt : 'Falling asleep',
        situation : 'Je gaat naar bed en probeert te slapen',
        options :
            [
                {
                    id : 1,
                    text : "Ik negeer het geluid en probeer te slapen."
                },
                {
                    id : 2,
                    text : "Ik gebruik oordopjes om goed te slapen."
                },
                {
                    id : 3,
                    text : "Ik zet een film of tv-serie op om mezelf af te leiden tot ik in slaap val."
                },
                {
                    id : 4,
                    text : "Ik ga naar een stillere kamer in mijn huis om te slapen."
                }
            ]
    }
};

export default gedrag;