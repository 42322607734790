import React from 'react'
import {Col, Container, Jumbotron, Row} from "react-bootstrap";
import TextComponent from "../common_components/TextComponent";
import ButtonPanel from "../common_components/ButtonPanel";
import {useSelector, useDispatch} from "react-redux";
import words from "../data_constant/verbal";
import {changeSituationCount, changeWordCount} from "./redux/actions";
import CustomSlider from "../common_components/CustomSlider";
import {pushVerbal} from "./server_connection/server_request";

function VerbalManager(props) {
    const wordCount = useSelector(store => store.wordCount);
    const verbal = useSelector(store => store.verbal[props.step]);
    const dispatch = useDispatch();
    const id = useSelector(store => store.soundscapeID)[props.step === 4 ? 3 : props.step];
    const key = useSelector(store => store.key);

    const next = () => {
        if(wordCount < words.length -4) {
            dispatch(changeWordCount(wordCount + 4))
        } else {
            if(props.step === 6) {
                dispatch(changeSituationCount(0))
            }
            pushVerbal(id, verbal, props.step, key);
            props.next()
        }
    };

    const prev = () => {
        if(wordCount>3) {
            dispatch(changeWordCount(wordCount-4))
        } else {
            props.prev()
        }
    };

    const hideText = () => {
        return wordCount !== 0;
    };

    const isInvalid = () => {return false};

    return (
        <Container fluid className = "adjustButton">
            <Row >
                <Col xs={12} xl={5}>
                    <TextComponent
                        hideText={hideText()}
                        substep={props.substep}/>
                </Col>
                <Col xs={12} xl={7}>
                    <Container className = "my-lg-4 my-xs-0">
                        {words.slice(wordCount, wordCount + 4).map(x=>
                            <Row className = "pb-4 pb-xl-5">
                                {x.length >= 2? <CustomSlider substep = {props.substep} label = {x[2]} displayTag = {true} options = {[x[0], x[1]]}/> : null}
                            </Row>
                        )}
                    </Container>
                </Col>
            </Row>
            <ButtonPanel next = {next} prev = {prev} isInvalid = {() => isInvalid()}/>
        </Container>
    )
}

export default VerbalManager;