export const auto_car_denoize =  require('./Auto.mp3');
export const bel_denoize =  require('./Bel.mp3');
export const brommer_denoize =  require('./Brommer.mp3');
export const buren_denoize =  require('./Buren.mp3');
export const grasmaaier_denoize =  require('./Grasmaaier.mp3');
export const helicopter_denoize =  require('./Helicopter.mp3');
export const metro_denoize =  require('./Metro.mp3');
export const plane_taking_off_denoize =  require('./Plane_taking_off.mp3');
export const seagul_denoize =  require('./Seagul.mp3');
export const siren_denoize =  require('./Siren.mp3');
export const snelweg_denoize =  require('./Snelweg.mp3');
export const street_cleaner_denoize =  require('./Street_cleaner.mp3');
export const terras_denoize =  require('./Terras.mp3');
export const tram_denoize =  require('./Tram.mp3');
export const trein_denoize =  require('./Trein.mp3');
export const vliegtuig_in_de_lucht_denoize =  require('./Vliegtuig_in_de_lucht.mp3');
export const vuilniswagen_denoize =  require('./Vuilniswagen.mp3');
export const wind_denoize =  require('./Wind.mp3');
export const windmolen_denoize =  require('./Windmolen.mp3');
export const basisschool_denoize =  require('./Basisschool.mp3');
export const electric_hum_denoize =  require('./Electric_hum.mp3');
export const motor_denoize =  require('./Motor.mp3');
export const bus_denoize =  require('./Vrachtwagen.mp3');
export const drilling_denoize =  require('./Drilling.mp3');
export const construction_denoize =  require('./Construction.mp3');
