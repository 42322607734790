import React from 'react'
import {Col, Container, Image, Jumbotron, Row} from "react-bootstrap";
import TextComponent from "../common_components/TextComponent";
import ButtonPanel from "../common_components/ButtonPanel";
import SensitivityImage from "./resource/img/sound_sensitivity.png";
import ImageContainer from "../common_components/ImageContainer";

function SoundSensitivityIntro(props) {

    if(props.substep !== 'sound_sensitivity_intro') {
        return null;
    }

    return (
        <Jumbotron>
            <Container fluid>
                <Row>
                    <Col xs={12} xl={5} xs={{order : 'last'}} xl={{order : 'first'}}>
                        <TextComponent hideText = {false} substep = {props.substep}/>
                    </Col>
                    <Col xs={12} xl={7} >
                        <ImageContainer image = {SensitivityImage}/>
                    </Col>

                </Row>
                <ButtonPanel next = {props.next} prev = {props.prev} isInvalid = {() => {return false}}/>
            </Container>
        </Jumbotron>

    )
}

export default SoundSensitivityIntro