/**
 * list of outdoor noise options
 * To be used as keys for noise icon dictionary
 */
const noise_icon = [
    'Vliegtuig in de lucht',
    'Opstijgend vliegtuig',
    'Snelweg',
    'Auto',
    'Sirene',
    'Windmolen',
    'Vuilniswagen',
    'Straatveger',
    'Helicopter',
    'Metro',
    'Trein',
    'Tram',
    'Grasmaaier',
    'School/kinderen die spelen',
    'Vrachtwagen',
    'Motor',
    'Brommer',
    'Terras',
    'Klokkenspel',
    'Schuurmachine',
    'Bouwgeluiden',
    'Buren', 'Elektrische brom',
    'Wind',
    'Meeuwen',

];

export default noise_icon;
