export const setInfo = (answer, field, substep) => {
    return {
        type : 'ADDINFORMATION',
        payload : {
            field : field,
            answer : answer,
            substep : substep
        }
    }
};

export const addOpenAnswer = (answer, substep) => {
    return {
        type : 'ADDOPENANSWER',
        payload : {
            substep : substep,
            answer : answer
        }
    }
};